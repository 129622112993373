import Constants from 'constants/index';
import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useUpdateTrainingPlanMutation } from 'services/pathwayApi';
import {
  selectAllLocationsWithAtLeastTrainer,
  selectLocationsWithLeaderPermission,
  selectLocationsWithOperatorPermission,
  selectUserLanguage,
} from 'store/user/selectors';
import { toast } from 'react-hot-toast';
import PropTypes from 'prop-types';
import Section from 'containers/TrainingPlans/BuildPlans/EditablePlanItems/Section';
import Bugsnag from '@bugsnag/browser';
import { useSelector } from 'react-redux';
import { formatBugsnagErrorMessage } from 'bugsnag';
import { Container, Draggable } from 'react-smooth-dnd';
import { arrayMoveImmutable } from 'array-move';
import { Card } from 'cfa-react-components';
import ConfirmationModal from 'sharedComponents/app/popups/ConfirmationModal';
import ToastMessageBlock from 'sharedComponents/app/Toasts/SuccessToast';
import TaskContainer from './TaskContainer';

const PlanItems = ({
  immutablePlan,
  refetch,
  addProcedure,
  addQuiz,
  setIsDrop,
}) => {
  const { t } = useTranslation();
  const [updateTrainingPlan] = useUpdateTrainingPlanMutation();
  const userLanguage = useSelector(selectUserLanguage);
  const [editMode, setEditMode] = useState(false);
  const [sectionBeingRenamed, setSectionBeingRenamed] = useState({});
  const [taskToDelete, setTaskToDelete] = useState({});
  const [showDeleteTaskPopup, setShowDeleteTaskPopup] = useState(false);
  const [showDeleteSectionPopup, setShowDeleteSectionPopup] = useState(false);
  const [activeSectionId, setActiveSectionId] = useState('');
  const [indexToAdd, setIndexToAdd] = useState('');
  const [plan, setPlan] = useState(immutablePlan);

  const locationsWithAtLeastTrainer = useSelector(
    selectAllLocationsWithAtLeastTrainer,
  );

  const locationsWithOperatorPermission = useSelector(
    selectLocationsWithOperatorPermission,
  );
  const locationsWithLeaderPermission = useSelector(
    selectLocationsWithLeaderPermission,
  );

  useEffect(() => {
    if (immutablePlan) {
      setPlan(immutablePlan);
    }
  }, [immutablePlan]);

  const onDeleteSectionCancel = () => {
    setSectionBeingRenamed({ id: '', name: '' });
    setShowDeleteSectionPopup(false);
  };

  const onDeleteSection = section => {
    setSectionBeingRenamed({ id: '', name: '' });
    setShowDeleteSectionPopup(false);
    const payload = {
      locations: plan.locations,
      checklist: {
        ...plan,
        sections: [
          ...plan.sections.filter(value => {
            return !(value?.id === section.id);
          }),
        ],
      },
    };

    updateTrainingPlan(payload)
      .unwrap()
      .then(() => {
        refetch();
        toast.custom(toastObj => (
          <ToastMessageBlock id={toastObj.id}>
            {`${section.name} ${t(
              'TrainingPlans.toastMessage.sectionDeleted',
            )}`}
          </ToastMessageBlock>
        ));
      })
      .catch(err => {
        Bugsnag.notify(formatBugsnagErrorMessage(err));
      });
  };

  const deleteSectionPopUp = (sectionId, sectionName) => {
    setSectionBeingRenamed({ id: sectionId, name: sectionName });
    setShowDeleteSectionPopup(true);
  };

  const isAllowedToEdit = () => {
    if (locationsWithAtLeastTrainer) {
      return true;
    }
    if (locationsWithOperatorPermission.length > 0) {
      return true;
    }
    return locationsWithLeaderPermission.length > 0;
  };

  const renameSectionPopUp = sectionId => {
    setEditMode(true);
    setSectionBeingRenamed({ id: sectionId, name: '' });
  };

  const updateSectionName = updatedSectionTitle => {
    if (!updatedSectionTitle.trim()) {
      return;
    }

    const sections = sectionBeingRenamed
      ? plan.sections.map(section =>
          section.id === sectionBeingRenamed.id
            ? {
                ...section,
                name: {
                  ...(section?.name || {}),
                  [userLanguage]: updatedSectionTitle,
                },
              }
            : section,
        )
      : plan.sections;
    const payload = {
      locations: plan.locations,
      checklist: { ...plan, sections },
    };

    saveUpdates(payload);
  };

  const [taskUpdated, setTaskUpdated] = useState(false);

  const updateTask = (task, sectionId) => {
    const sections = plan.sections.map(section =>
      section.id === sectionId
        ? {
            ...section,
            steps: section.steps.map(step => {
              return step.id === task.id
                ? {
                    ...step,
                    name: { ...step.name, [userLanguage]: task.name },
                    note: { ...step?.note, [userLanguage]: task.note },
                    urls: [
                      {
                        urlSet: {
                          ...step?.urls?.[0]?.urlSet,
                          [userLanguage]: task.url,
                        },
                      },
                    ],
                  }
                : step;
            }),
          }
        : section,
    );

    const steps = plan.steps.map(step => {
      return step.id === task.id
        ? {
            ...step,
            name: { ...step.name, [userLanguage]: task.name },
            note: { ...step?.note, [userLanguage]: task.note },
            urls: [
              {
                urlSet: {
                  ...step?.urls?.[0]?.urlSet,
                  [userLanguage]: task.url,
                },
              },
            ],
          }
        : step;
    });

    const payload = {
      locations: plan.locations,
      checklist: sectionId ? { ...plan, sections } : { ...plan, steps },
    };

    saveUpdates(payload);
    setTaskUpdated(true);
  };

  const onUpdate = (type, updatedSectionTitle, task, sectionId) => {
    type === Constants.TRAINING_MENU_OPTIONS.SECTION
      ? updateSectionName(updatedSectionTitle)
      : updateTask(task, sectionId);
    setEditMode(false);
    setSectionBeingRenamed({ id: '', name: '' });
  };

  const saveUpdates = payload => {
    updateTrainingPlan(payload)
      .unwrap()
      .then(() => {
        setEditMode(false);
        setSectionBeingRenamed({ id: '', name: '' });
        refetch();
      })
      .catch(err => {
        Bugsnag.notify(formatBugsnagErrorMessage(err));
      });
  };

  const editSectionTitle = section => {
    return (
      isAllowedToEdit() && editMode && sectionBeingRenamed.id === section.id
    );
  };

  const shouldHideDropdownMenu = () => {
    return editMode || showDeleteSectionPopup;
  };

  const onDeleteTaskInsideSection = ({ id, name, sectionId, type }) => {
    setTaskToDelete({ id, name, sectionId, type });
    setShowDeleteTaskPopup(true);
  };

  const onDeleteTask = () => {
    const steps = taskToDelete.sectionId
      ? plan.steps
      : plan.steps.filter(step => step.id !== taskToDelete.id);
    const sections = taskToDelete.sectionId
      ? plan.sections.map(section => {
          return {
            ...section,
            steps: section.steps.filter(step => step.id !== taskToDelete.id),
          };
        })
      : plan.sections;

    const payload = {
      locations: plan.locations,
      checklist: {
        ...plan,
        steps,
        sections,
      },
    };

    updateTrainingPlan(payload)
      .unwrap()
      .then(() => {
        refetch();
        toast.custom(toastObj => (
          <ToastMessageBlock id={toastObj.id}>
            {`${taskToDelete.name} ${t('TrainingPlans.toastMessage.deleted')}`}
          </ToastMessageBlock>
        ));
        setShowDeleteTaskPopup(false);
      })
      .catch(err => {
        Bugsnag.notify(formatBugsnagErrorMessage(err));
      });
  };

  const onCancel = () => {
    setEditMode(false);
  };

  const onDeleteTaskOutsideSection = ({ id, name, type }) => {
    setTaskToDelete({ id, name, sectionId: null, type });
    setShowDeleteTaskPopup(true);
  };

  const updateArrayIndex = (array, removedIndex, addedIndex) => {
    if (removedIndex < 0 || addedIndex < 0) {
      return;
    }

    return arrayMoveImmutable(array, removedIndex, addedIndex).map(
      (item, idx) => {
        return { ...item, order: idx };
      },
    );
  };

  let stepToMove;

  const onDrop = (type, dropResult, sectionId) => {
    if (
      dropResult.removedIndex === null ||
      dropResult.removedIndex === dropResult.addedIndex
    ) {
      return;
    }
    const { removedIndex, addedIndex, payload } = dropResult;
    setIsDrop(true);
    const movingStepOutOfSection =
      payload?.sectionId &&
      activeSectionId === '' &&
      type === Constants.DRAGGABLE_TYPES.STEP_IN_SECTION
        ? true
        : false;

    const movingStepIntoSection =
      payload?.sectionId === undefined &&
      activeSectionId &&
      type === Constants.DRAGGABLE_TYPES.STEP
        ? true
        : false;

    const movingStepInsideSection =
      (payload?.sectionId === activeSectionId && removedIndex && addedIndex) ||
      (payload?.sectionId === activeSectionId &&
        (removedIndex === 0 || addedIndex === 0))
        ? true
        : false;

    const movingStepInBetweenSections =
      payload?.sectionId &&
      payload?.sectionId !== activeSectionId &&
      type === Constants.DRAGGABLE_TYPES.STEP_IN_SECTION
        ? true
        : false;

    const movingIndividualSteps =
      !activeSectionId &&
      !payload?.sectionId &&
      type === Constants.DRAGGABLE_TYPES.STEP
        ? true
        : false;

    stepToMove = movingStepOutOfSection
      ? plan?.sections
          .filter(section => {
            return section.id === payload.sectionId;
          })?.[0]
          ?.steps.find(step => step.id === payload.id)
      : movingStepIntoSection
      ? plan.steps.filter(step => step.id === payload.id)[0]
      : null;

    const moveBetweenSections = () => {
      stepToMove = plan?.sections
        .filter(section => section.id === payload.sectionId)?.[0]
        ?.steps.filter(step => step.id === payload.id)[0];

      const updatedSections = [
        ...plan.sections.map(section => {
          return section?.id === payload.sectionId
            ? {
                ...section,
                steps: [
                  ...section.steps.filter(step => step.id !== payload.id),
                ],
              }
            : { ...section };
        }),
      ];

      return [
        ...updatedSections.map(section => {
          return section?.id === activeSectionId
            ? {
                ...section,
                steps: updateArrayIndex(
                  [...section.steps, stepToMove],
                  [...section.steps, stepToMove].length - 1,
                  indexToAdd,
                ),
              }
            : { ...section };
        }),
      ];
    };

    const updatedSections =
      // Moving section
      type === Constants.DRAGGABLE_TYPES.SECTION
        ? updateArrayIndex(plan.sections, removedIndex, addedIndex)
        : movingStepInsideSection
        ? [
            ...plan.sections.map(section => {
              return section?.id === sectionId
                ? {
                    ...section,
                    steps: updateArrayIndex(
                      section.steps,
                      removedIndex,
                      addedIndex,
                      sectionId,
                    ),
                  }
                : { ...section };
            }),
          ]
        : movingStepOutOfSection
        ? [
            ...plan.sections.map(section => {
              return section?.id === payload.sectionId
                ? {
                    ...section,
                    steps: section.steps.filter(step => step.id !== payload.id),
                  }
                : { ...section };
            }),
          ]
        : movingStepIntoSection
        ? [
            ...plan.sections.map(section => {
              return section?.id === activeSectionId
                ? {
                    ...section,
                    steps: updateArrayIndex(
                      [...section.steps, stepToMove],
                      [...section.steps, stepToMove].length - 1,
                      indexToAdd,
                    ),
                  }
                : { ...section };
            }),
          ]
        : movingStepInBetweenSections
        ? moveBetweenSections()
        : plan.sections;

    const updatedSteps = movingIndividualSteps
      ? updateArrayIndex(plan.steps, removedIndex, addedIndex)
      : movingStepOutOfSection ||
        (movingStepInBetweenSections && !activeSectionId)
      ? updateArrayIndex(
          [...plan.steps, stepToMove],
          [...plan.steps, stepToMove].length - 1,
          indexToAdd,
        )
      : movingStepIntoSection
      ? plan.steps.filter(step => step.id !== payload.id)
      : plan.steps;

    const pay_load = {
      locations: plan.locations,
      checklist: {
        ...plan,
        sections: updatedSections,
        steps: updatedSteps,
      },
    };

    if (
      stepToMove ||
      /** These typed out null or undefined checks are necessary over the shorthand
       * ie !stepToMove.  I haven't been able to figure out why but the logic just
       * breaks without these explicity set */
      (stepToMove === undefined && removedIndex && addedIndex) ||
      (stepToMove === null && removedIndex !== null && addedIndex !== null)
    ) {
      setPlan(prevState => ({
        ...prevState,
        sections: updatedSections,
        steps: updatedSteps,
      }));
      updateTrainingPlan(pay_load)
        .unwrap()
        .then(() => {
          refetch();
        })
        .catch(err => {
          Bugsnag.notify(formatBugsnagErrorMessage(err));
        });
    }
  };

  return (
    <>
      <ContainerWrapper>
        <Container
          dragHandleSelector=".step-drag-handle"
          dropPlaceholder={{
            className: 'shadow-on-drop',
          }}
          getChildPayload={index => plan?.steps?.[index]}
          groupName="steps"
          nonDragAreaSelector=".cfa-textfield"
          onDragEnter={() => setActiveSectionId('')}
          onDrop={e => onDrop(Constants.DRAGGABLE_TYPES.STEP, e)}
          onDropReady={addedIndex => setIndexToAdd(addedIndex.addedIndex)}
        >
          {plan?.steps?.map((step, idx) => (
            <DraggablePlanItem
              data-testid="Draggable"
              key={`${step.id}-${idx}`}
            >
              <PlanItemCard elevation={1}>
                <TaskContainer
                  className="step-drag-handle"
                  isAllowedToEdit={isAllowedToEdit}
                  onCancel={onCancel}
                  onDelete={onDeleteTaskOutsideSection}
                  onEdit={(type, updatedSectionTitle, task, sectionId) =>
                    onUpdate(type, updatedSectionTitle, task, sectionId)
                  }
                  plan={plan}
                  step={step}
                  taskUpdated={taskUpdated}
                />
              </PlanItemCard>
            </DraggablePlanItem>
          ))}
        </Container>
      </ContainerWrapper>

      <ContainerWrapper>
        <Container
          dragHandleSelector=".section-drag-handle"
          dropPlaceholder={{
            className: 'shadow-on-drop',
          }}
          nonDragAreaSelector=".cfa-textfield"
          onDrop={e => onDrop(Constants.DRAGGABLE_TYPES.SECTION, e)}
        >
          {plan?.sections?.map((section, idx) => (
            <Draggable key={section.id}>
              <Section
                addProcedure={() => addProcedure(section.id)}
                addQuiz={() => addQuiz(section.id)}
                deleteSectionPopUp={deleteSectionPopUp}
                editMode={editMode}
                editSectionTitle={editSectionTitle}
                index={idx}
                isAllowedToEdit={isAllowedToEdit}
                onCancel={onCancel}
                onClickThreeDotMenuDeleteTask={onDeleteTaskInsideSection}
                onDrop={(e, id) =>
                  onDrop(Constants.DRAGGABLE_TYPES.STEP_IN_SECTION, e, id)
                }
                onEdit={(type, updatedSectionTitle, task, id) =>
                  onUpdate(type, updatedSectionTitle, task, id)
                }
                plan={plan}
                refetch={refetch}
                renameSectionPopUp={renameSectionPopUp}
                section={section}
                setActiveSectionId={id => setActiveSectionId(id)}
                setIndexToAdd={index => setIndexToAdd(index.addedIndex)}
                shouldHideDropdownMenu={shouldHideDropdownMenu()}
                taskUpdated={taskUpdated}
              />
            </Draggable>
          ))}
        </Container>
      </ContainerWrapper>
      <ConfirmationModal
        bodyText={t('Generic.deleteObject', {
          name: sectionBeingRenamed.name ?? '',
          object: t('Generic.section').toLowerCase(),
        })}
        headerText={t('Generic.deleteHeader', {
          type: t('Generic.section'),
        })}
        isOpen={showDeleteSectionPopup}
        onClose={onDeleteSectionCancel}
        primaryButtonHandler={() => onDeleteSection(sectionBeingRenamed)}
        primaryButtonText={t('Button.delete')}
        primaryButtonVariant="destructive"
        secondaryButtonHandler={onDeleteSectionCancel}
        secondaryButtonText={t('Button.cancel')}
      />
      <ConfirmationModal
        bodyText={
          taskToDelete.type === Constants.STEP_TYPES.TASK
            ? t('Generic.deleteObject', {
                name: taskToDelete.name ?? '',
                object: t('Generic.task').toLowerCase(),
              })
            : t('Generic.removeObject', {
                name: taskToDelete.name ?? '',
                object:
                  taskToDelete.type === Constants.STEP_TYPES.DOCUMENT
                    ? t('Generic.resource').toLowerCase()
                    : t('Generic.quiz').toLowerCase(),
              })
        }
        headerText={
          taskToDelete.type === Constants.STEP_TYPES.TASK
            ? t('Generic.deleteHeader', {
                type: t('Generic.task'),
              })
            : t('Generic.removeHeader', {
                type:
                  taskToDelete.type === Constants.STEP_TYPES.DOCUMENT
                    ? t('Generic.resource')
                    : taskToDelete.type === Constants.STEP_TYPES.QUIZ
                    ? t('Generic.quiz')
                    : t('Generic.task'),
              })
        }
        isOpen={showDeleteTaskPopup}
        onClose={() => {
          setShowDeleteTaskPopup(false);
        }}
        primaryButtonHandler={onDeleteTask}
        primaryButtonText={
          taskToDelete.type === Constants.STEP_TYPES.TASK
            ? t('Button.delete')
            : t('Button.remove')
        }
        primaryButtonVariant="destructive"
        secondaryButtonHandler={() => {
          setShowDeleteTaskPopup(false);
        }}
        secondaryButtonText={t('Button.cancel')}
      />
    </>
  );
};

const PlanItemCard = styled(Card)`
  margin: 24px 0;
  padding: 16px;
`;

const DraggablePlanItem = styled(Draggable)`
  &:last-child ${PlanItemCard} {
    margin-bottom: 0;
  }
`;

const ContainerWrapper = styled.div`
  width: 100%;
  .shadow-on-drop {
    background-color: ${({ theme }) => theme.grayScale.gray2};
    padding: 10px;
    border-radius: 5px;
  }
`;

PlanItems.propTypes = {
  immutablePlan: PropTypes.object.isRequired,
  refetch: PropTypes.func.isRequired,
  addProcedure: PropTypes.func.isRequired,
  addQuiz: PropTypes.func.isRequired,
  setIsDrop: PropTypes.func.isRequired,
};

export default PlanItems;
