import Constants from 'constants/index';
import { getNameFromLanguage } from 'util/language';
import { getIconFromReference } from 'util/reference';
import { useTranslation } from 'react-i18next';
import { Draggable } from 'react-smooth-dnd';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { selectUserCountry } from 'store/user/selectors';
import { VideoSearchResult } from 'icons';
import { IconButton, Tag } from 'cfa-react-components';
import {
  IconCornerDownRight,
  IconEye,
  IconEyeOff,
  IconGripVertical,
  IconTrash,
} from '@tabler/icons-react';
const Document = props => {
  const {
    document,
    openInNewTab,
    RenderDocumentEndContent,
    inSectionPreview,
    documentIsCurrentlyVisible,
    handleDocumentVisibilityToggle,
    handleDocumentDeleteButton,
    disableSixDotMenuHandles,
  } = props;
  const {
    name: docName,
    id: docId,
    references: ref,
    type: documentType,
  } = document;
  const { t } = useTranslation();
  const selectedCountry = useSelector(selectUserCountry);
  const documentId = docId || getNameFromLanguage(ref)?.documentId;
  const docIcon = ref
    ? getIconFromReference(ref)
    : document.icon === 'placeholder'
    ? Constants.DEFAULT_PLACEHOLDER_ICON
    : document.icon;

  return (
    <>
      <Draggable key={`document-${docId}`}>
        <StyledContainerDocument>
          {!inSectionPreview ? (
            <Link
              data-testid={documentId}
              key={documentId}
              rel={openInNewTab ? 'noopener noreferrer' : ''}
              target={openInNewTab ? '_blank' : '_self'}
              to={`/${Constants.ROUTE_PATH_NAMES.DOCUMENT_PATH_NAME}/${documentId}`}
            >
              <StyledDocument>
                {documentType?.toLowerCase() ===
                Constants.EXPANDED_FILE_FORMAT_TYPES.BRIGHTCOVE ? (
                  <StyledVideoSearchResultIcon />
                ) : (
                  <StyledDocumentImage
                    alt=""
                    src={`${Constants.PATHWAY_CDN_IMG.PROCEDURES}${
                      docIcon ?? Constants.DEFAULT_PLACEHOLDER_ICON
                    }.svg`}
                  />
                )}
                {getNameFromLanguage(docName, selectedCountry.id) || docName}
              </StyledDocument>
            </Link>
          ) : (
            <>
              {!disableSixDotMenuHandles && (
                <StyledSixDocumentDotIcon className="document-drag-handle" />
              )}
              <StyledDocumentPreview>
                {documentType?.toLowerCase() ===
                Constants.EXPANDED_FILE_FORMAT_TYPES.BRIGHTCOVE ? (
                  <StyledVideoSearchResultIcon />
                ) : (
                  <StyledDocumentImage
                    alt=""
                    src={`${Constants.PATHWAY_CDN_IMG.PROCEDURES}${
                      docIcon ?? Constants.DEFAULT_PLACEHOLDER_ICON
                    }.svg`}
                  />
                )}
                {getNameFromLanguage(
                  docName,
                  Constants.LANGUAGE_OPTIONS.ENGLISH,
                )}
              </StyledDocumentPreview>
              <StyledHideDocumentWrapper
                onClick={() => handleDocumentVisibilityToggle()}
              >
                {documentIsCurrentlyVisible ? (
                  <StyledShowTag
                    data-testid="EditDocumentToggleShow"
                    label={t('Admin.categoryIsVisible')}
                    leadingElement={<IconEye width={10} />}
                    variant="filled"
                  />
                ) : (
                  <StyledHideTag
                    data-testid="EditDocumentToggleHide"
                    disabled
                    label={t('Admin.categoryHidden')}
                    leadingElement={<IconEyeOff width={10} />}
                    variant="filled"
                  />
                )}
              </StyledHideDocumentWrapper>
              <StyledDeleteDocumentIconButton
                aria-label="Delete Category"
                color="default"
                data-testid="EditCategoryTrashIcon"
                href=""
                onClick={handleDocumentDeleteButton}
                size="md"
              >
                <IconTrash />
              </StyledDeleteDocumentIconButton>
            </>
          )}
          {RenderDocumentEndContent && (
            <RenderDocumentEndContent document={document} />
          )}
        </StyledContainerDocument>
        {!inSectionPreview ? null : (
          <>
            <SpanishTranslationHeader>
              {t('Admin.spanishTranslation')}
            </SpanishTranslationHeader>
            <StyledSpanishDocumentPreview>
              <IconCornerDownRight size={35} />
              {documentType?.toLowerCase() ===
              Constants.EXPANDED_FILE_FORMAT_TYPES.BRIGHTCOVE ? (
                <StyledVideoSearchResultIcon />
              ) : (
                <StyledDocumentImage
                  alt=""
                  src={`${Constants.PATHWAY_CDN_IMG.PROCEDURES}${
                    docIcon ?? Constants.DEFAULT_PLACEHOLDER_ICON
                  }.svg`}
                />
              )}
              {getNameFromLanguage(docName, Constants.LANGUAGE_OPTIONS.SPANISH)}
            </StyledSpanishDocumentPreview>
          </>
        )}
      </Draggable>
    </>
  );
};

Document.propTypes = {
  document: PropTypes.object.isRequired,
  openInNewTab: PropTypes.bool,
  RenderDocumentEndContent: PropTypes.func,
  inSectionPreview: PropTypes.bool,
  documentIsCurrentlyVisible: PropTypes.bool,
  handleDocumentVisibilityToggle: PropTypes.func,
  handleDocumentDeleteButton: PropTypes.func,
  disableSixDotMenuHandles: PropTypes.bool,
};

Document.defaultProps = {
  openInNewTab: false,
  RenderDocumentEndContent: null,
  inSectionPreview: false,
  documentIsCurrentlyVisible: false,
  handleDocumentVisibilityToggle: () => {},
  handleDocumentDeleteButton: () => {},
  disableSixDotMenuHandles: false,
};

const StyledSixDocumentDotIcon = styled(IconGripVertical)`
  position: absolute;
  left: 0;
  width: 25px;
  height: 25px;
  margin-left: 2em;
  cursor: move;
  z-index: 2;
  color: ${({ theme }) => theme.grayScale.gray3};
`;

const StyledShowTag = styled(Tag)`
  flex-direction: row-reverse;
  align-items: center;
  margin-left: 16px;
  height: fit-content;

  .tag-leading-element {
    margin: 0 0 0 6px;
    width: unset;
    height: unset;
  }
`;

const SpanishTranslationHeader = styled.div`
  margin: 0 0 0 115px;
  padding: 0;
`;

const StyledHideDocumentWrapper = styled('span')`
  margin-right: 18px;
  cursor: pointer;
`;

const StyledHideTag = styled(Tag)`
  flex-direction: row-reverse;
  align-items: center;
  height: fit-content;

  .tag-leading-element {
    margin: 0 0 0 6px;
    width: unset;
    height: unset;
  }
`;

const StyledVideoSearchResultIcon = styled(VideoSearchResult)`
  margin-right: 10px;
`;

const StyledContainerDocument = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const StyledDocument = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: 20px;
  margin: 15px 0;
`;

const StyledDocumentPreview = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: 20px;
  margin: 15px 60px;
  width: 100%;
`;

const StyledSpanishDocumentPreview = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: 20px;
  margin: 8px 75px;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
`;

const StyledDocumentImage = styled.img`
  width: 40px !important;
  height: 40px !important;
  margin-right: 10px;
  border-radius: 0;
  padding: 1px;
`;

const StyledDeleteDocumentIconButton = styled(IconButton)`
  margin-left: 0;
  margin-right: 36px;
`;

export default Document;
