import { getNameFromLanguage } from 'util/language';
import { mapCategoryToAliasTranslation } from 'util/categoryUtils';
import Constants from 'constants/index';
import PropTypes from 'prop-types';
import PlanCard from 'components/PlanCard/PlanCard';
import { useTranslation } from 'react-i18next';
import PlanCardContent from 'components/PlanCard/PlanCardContent/PlanCardContent';
import { IconPrinter } from '@tabler/icons-react';
import styled from 'styled-components';
import {
  Typography,
  useBreakpoints,
  useMediaQuery,
} from 'cfa-react-components';
import PlanCardTitle from '../PlanCardComponents/PlanCardTitle';
import PlanCardLabel from '../PlanCardComponents/PlanCardLabel';
import PlanCardSubHeader from '../PlanCardComponents/PlanCardSubHeader';

const ReportsPlanCard = ({
  assignedUsers,
  className,
  completedUsers,
  'data-testid': dataTestId,
  locations,
  onPrintReport,
  plan,
}) => {
  const { t } = useTranslation();
  const breakpoints = useBreakpoints();
  const isSmAndDown = useMediaQuery(breakpoints.down('sm'));
  const percentComplete = (completedUsers / assignedUsers) * 100;

  return (
    <>
      {!!plan && (
        <>
          <PlanCard
            buttons={
              <StyledPrinterIcon
                onClick={e => {
                  e.preventDefault();
                  onPrintReport({
                    category: plan?.category,
                    id: plan?.id,
                    locations: locations,
                  });
                }}
              />
            }
            category={plan?.category}
            className={className}
            data-testid={dataTestId}
            link={`/${Constants.ROUTE_PATH_NAMES.REPORTS_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.PLANS_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.PROGRESS_PATH_NAME}/${plan?.id}`}
            progress={percentComplete}
          >
            <PlanCardContent>
              <PlanCardLabel>
                {t(mapCategoryToAliasTranslation(plan?.category))}
              </PlanCardLabel>
              <InlineText $isSmAndDown={isSmAndDown}>
                <PlanCardTitle>{getNameFromLanguage(plan?.name)}</PlanCardTitle>
                <Typography variant="body2">
                  {t('Generic.created', {
                    createdDate: new Date(plan?.created).toLocaleDateString(),
                  })}
                </Typography>
              </InlineText>
              {!!assignedUsers && completedUsers === assignedUsers ? (
                <PlanCardSubHeader
                  className="success"
                  data-testid="PlanTaskCount"
                >
                  {t('Generic.completedByAll')}
                </PlanCardSubHeader>
              ) : (
                <PlanCardSubHeader data-testid="PlanTaskCount">
                  {`${completedUsers}/${assignedUsers} ${t(
                    'Generic.teamMembersCompleted',
                  )}`}
                </PlanCardSubHeader>
              )}
            </PlanCardContent>
          </PlanCard>
        </>
      )}
    </>
  );
};

ReportsPlanCard.propTypes = {
  assignedUsers: PropTypes.number,
  className: PropTypes.string,
  completedUsers: PropTypes.number,
  'data-testid': PropTypes.string,
  locations: PropTypes.arrayOf(PropTypes.string),
  onPrintReport: PropTypes.func.isRequired,
  plan: PropTypes.shape({
    category: PropTypes.string,
    created: PropTypes.string,
    estimatedMinutes: PropTypes.number,
    id: PropTypes.string,
    locations: PropTypes.array,
    name: PropTypes.shape({
      en: PropTypes.string,
    }),
    ownerId: PropTypes.string,
    stepsTotal: PropTypes.number,
  }).isRequired,
};

ReportsPlanCard.defaultProps = {
  assignedUsers: 0,
  className: '',
  completedUsers: 0,
  'data-testid': 'ManagePlansPlanCard',
  locations: [],
};

const StyledPrinterIcon = styled(IconPrinter)`
  pointer-events: auto;
  color: ${({ theme }) => theme.grayScale.gray2};
`;

const InlineText = styled.div`
  display: flex;
  flex-direction: ${({ $isSmAndDown }) => ($isSmAndDown ? 'column' : 'row')};
  gap: ${({ $isSmAndDown }) => ($isSmAndDown ? '0px' : '8px')};
`;

export default ReportsPlanCard;
