import Constants from 'constants/index';
import { getNameFromLanguage } from 'util/language';
import { useDeviceInfo } from 'util/device';
import { useCallback, useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import { useGetDocumentQuery } from 'services/pathwayApi';
import LoadingOverlay from 'sharedComponents/app/LoadingOverlay';
import styled from 'styled-components';
import DocumentHead from 'DocumentHead';
import PropTypes from 'prop-types';
import includes from 'lodash/includes';
import { useDispatch } from 'react-redux';
import { setSidebarCollapsed } from 'store/sideBar/slice';
import { incrementHistoryStackCount } from 'store/header/slice';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import ReactPlayerLoader from '@brightcove/react-player-loader';
import { Card, CardContent, Typography } from 'cfa-react-components';
import GenericError from 'sharedComponents/app/GenericError';
import useDocumentCookieRefresh from 'hooks/useDocumentCookieRefresh';
import ConfirmationModal from 'sharedComponents/app/popups/ConfirmationModal';

const SignalDocument = () => {
  const { t } = useTranslation();
  const [errorMsg, setErrorMessage] = useState(null);
  const [iframeLoads, setIframeLoads] = useState(0);
  const { documentId } = useParams();
  const { isDesktop: isDesktopWidth } = useDeviceInfo();
  const {
    data: document_detail = {},
    isFetching,
    error,
  } = useGetDocumentQuery(documentId, {
    refetchOnMountOrArgChange: true,
  });

  const { isSuccess: isDocumentCookieSuccess } = useDocumentCookieRefresh();
  // we always refetch document query to make sure we get a valid auth cookie
  // We shouldnt display any docs when we know the url is missing either
  // the versionId or fileId

  Document.propTypes = {
    id: PropTypes.string,
    planId: PropTypes.string,
    stepId: PropTypes.string,
    userIds: PropTypes.arrayOf(PropTypes.string),
    stepStatus: PropTypes.string,
    isViewingFromTrainingPlan: PropTypes.bool,
  };

  Document.defaultProps = {
    id: '',
    planId: '',
    stepId: '',
    userIds: [],
    stepStatus: '',
    isViewingFromTrainingPlan: false,
  };
  const versionId =
    getNameFromLanguage(document_detail?.references)?.versionId ??
    getNameFromLanguage(document_detail?.references)?.id;
  const fileId =
    getNameFromLanguage(document_detail?.references)?.fileId ??
    document_detail?.id;
  const gameUrl = getNameFromLanguage(document_detail?.references)?.reference;
  const documentName = getNameFromLanguage(document_detail?.references)?.name;
  const documentType = document_detail?.type;
  const documentFormat = document_detail?.format;
  const dispatch = useDispatch();
  const fullViewDocument = includes(
    Constants.EXPANDED_FILE_FORMAT_TYPES,
    documentType,
  );

  const [unauthorizedError, setUnauthorizedError] = useState(false);

  useEffect(() => {
    if (error && error.status === 403) {
      setUnauthorizedError(true);
    }
  }, [error]);

  const onMessageReceivedFromContent = useCallback(eventMessage => {
    if (eventMessage.origin === Constants.PATHWAY_API_URL) {
      // This is a message sent to indicate a successful page load
      if (eventMessage.data === 'ok') {
        return;
      }

      if (eventMessage.data.type === 'Error') {
        setErrorMessage(eventMessage.data.code);
      }
    }
  }, []);

  const onIframeLoad = () => {
    const updatedIframeLoads = iframeLoads + 1;
    setIframeLoads(updatedIframeLoads);
    if (updatedIframeLoads > 1) {
      dispatch(incrementHistoryStackCount());
    }
  };

  useEffect(() => {
    if (fullViewDocument) {
      dispatch(setSidebarCollapsed());
    }
  }, [dispatch, fullViewDocument]);

  useEffect(() => {
    window.addEventListener('message', onMessageReceivedFromContent);

    return () => {
      window.removeEventListener('message', onMessageReceivedFromContent);
    };
  }, [onMessageReceivedFromContent]);

  // Catch iFrame scroll events and forward them to the native app
  window.updateScrollPosition = top => {
    const scrollEvent = new MessageEvent('iFrameScroll', {
      data: JSON.stringify(top),
    });
    window.dispatchEvent(scrollEvent);
  };

  const content_url =
    documentType === Constants.EXPANDED_FILE_FORMAT_TYPES.GAME
      ? gameUrl
      : documentType === Constants.EXPANDED_FILE_FORMAT_TYPES.JOB_AID
      ? `${
          Constants.PATHWAY_API_CONTENT_URL
        }/${versionId}/${fileId}/${encodeURIComponent(documentName)}`
      : documentFormat === Constants.EXPANDED_FILE_FORMAT_TYPES.SCORM
      ? `${Constants.PATHWAY_API_CONTENT_URL}/${versionId}/${fileId}/scorm-index.html`
      : `${Constants.PATHWAY_API_CONTENT_URL}/${versionId}/${fileId}/index.html`;

  const handleSuccessVideoLoad = () => {};

  const content_iframe = () => {
    if (!versionId || !fileId) {
      return null;
    }
    if (
      documentType?.toLowerCase() ===
      Constants.EXPANDED_FILE_FORMAT_TYPES.BRIGHTCOVE
    ) {
      const videoReference = getNameFromLanguage(document_detail?.references);
      if (!videoReference) {
        return <GenericError />;
      }
      const brightcoveSrcUrl = new URL(videoReference.src);
      const brightcoveAccountId = brightcoveSrcUrl.pathname.match(/\d+/);
      const brightcoveVideoId = brightcoveSrcUrl.searchParams.get('videoId');
      return (
        <StyledVideoContainer>
          <StyledVideoTitle $isDesktop={isDesktopWidth}>
            {documentName}
          </StyledVideoTitle>
          <StyledVideoCard $isDesktop={isDesktopWidth} elevation={1}>
            <CardContent>
              <ReactPlayerLoader
                accountId={brightcoveAccountId}
                onSuccess={handleSuccessVideoLoad}
                options={{
                  aspectRatio: '16:9',
                  autoplay: false,
                  controls: false,
                  fluid: true,
                  loop: false,
                  muted: false,
                  playsInline: true,
                  responsive: true,
                }}
                videoId={brightcoveVideoId}
              />
            </CardContent>
          </StyledVideoCard>
        </StyledVideoContainer>
      );
    }
    return (
      <>
        {isDocumentCookieSuccess && (
          <StyledIFrame
            allow="fullscreen"
            className="content-iframe"
            onLoad={onIframeLoad}
            src={content_url}
          />
        )}
      </>
    );
  };

  return (
    <Container className="p-0 h-100" fluid>
      <LoadingOverlay isOpen={isFetching} />
      <DocumentHead pageTitle={documentName} />
      <StyledContentWrapper>
        {!errorMsg && content_iframe()}

        <ConfirmationModal
          bodyText={t('ResourceNotFoundError.errorParagraph')}
          headerText={t('GenericError.error')}
          isError={true}
          isOpen={errorMsg === Constants.API_ERROR_CODES.RESOURCE_NOT_FOUND}
          onClose={() => window.history.go(-1)}
          primaryButtonHandler={() => window.history.go(-1)}
          primaryButtonText={t('Button.returnToPreviousPage')}
        />

        <ConfirmationModal
          bodyText={t('LoadingResourceError.errorParagraph')}
          headerText={t('GenericError.error')}
          isError={true}
          isOpen={errorMsg === Constants.API_ERROR_CODES.ERROR_LOADING_RESOURCE}
          onClose={() => window.location.reload()}
          primaryButtonHandler={() => window.location.reload()}
          primaryButtonText={t('Button.reloadThePage')}
        />

        <ConfirmationModal
          bodyText={t('LoadingResourceError.unauthorizedParagraph')}
          headerText={t('GenericError.unauthorized')}
          isError={true}
          isOpen={unauthorizedError}
          onClose={() => (window.location.href = '/')}
          primaryButtonHandler={() => (window.location.href = '/')}
          primaryButtonText={t('Button.returnToHomepage')}
        />
      </StyledContentWrapper>
    </Container>
  );
};

const StyledVideoContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledVideoCard = styled(Card)`
  animation: fadeIn linear 0.3s;
  min-width: ${({ $isDesktop }) => ($isDesktop ? '840px !important' : null)};
  margin: ${({ $isDesktop }) => ($isDesktop ? '0 auto 2em auto' : '0.25em 0')};
`;

const StyledVideoTitle = styled(Typography)`
  color: ${({ theme }) => theme.grayScale.gray6};
  font-size: 32px;
  font-weight: 700;
  padding: 10px 0 24px 0;
  line-height: 40px;
  margin-top: 20px;
  margin-left: ${({ $isDesktop }) => ($isDesktop ? null : '1em')};
  text-align: ${({ $isDesktop }) => ($isDesktop ? 'center' : 'left')};
`;

const StyledContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 0;
`;

const StyledIFrame = styled.iframe`
  border: 0;
  width: 100%;
  min-height: calc(100vh - 85px);
`;

export default SignalDocument;
