import { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Typography } from 'cfa-react-components';
import RadioButtonList from 'sharedComponents/app/RadioButtonList';
import StepWizardPopUp from 'sharedComponents/app/popups/StepWizardPopUp';

const CopyTemplatePopup = ({
  isOpen,
  setOpen,
  operators,
  planName,
  reopenTemplates,
  submitHandler,
}) => {
  const ref = useRef();
  const isMultiOperator = operators?.length > 1;
  const initialStep = isMultiOperator ? 1 : 2;
  const [operator, setOperator] = useState({});
  const [operatorSelected, setOperatorSelected] = useState(false);
  const { t } = useTranslation();
  const [stepIndex, setStepIndex] = useState(initialStep);
  const [stepWizard, setStepWizard] = useState();
  const [input, setInput] = useState('');
  const operatorsNameArray = operators.flat().map(({ name }) => name);

  useEffect(() => {
    if (stepIndex === 2) {
      ref?.current?.focus();
    }
  }, [stepIndex]);

  useEffect(() => {
    if (operators.length) {
      if (operators.length === 1) {
        setOperator(operators[0]);
      }
    }
  }, [operators]);

  useEffect(() => {
    if (isOpen) {
      setInput(`${planName} ${t('TrainingPlans.duplicatePlanInputCopy')}`);
    } else {
      if (isMultiOperator) {
        setOperator({});
      }
      setStepIndex(initialStep);
      setOperatorSelected(false);
    }
  }, [initialStep, isMultiOperator, isOpen, planName, stepIndex, t]);

  const closePopup = () => {
    setStepIndex(initialStep);
    setOpen(false);
  };

  const selectOperator = operatorName => {
    const selectedOperator = operators.find(
      ({ name }) => name === operatorName,
    );
    setOperator(selectedOperator);
    setOperatorSelected(true);
  };

  const Step1 = () => (
    <>
      <DialogHeader variant="h3">
        {t('TrainingPlans.addPlanMenu.chooseOperator')}
      </DialogHeader>
      <RadioButtonList
        defaultValue={''}
        listItems={operatorsNameArray}
        name={'operatorList'}
        setValue={selectOperator}
      />
    </>
  );

  const Step2 = () => (
    <>
      <DialogHeader variant="h3">
        {t('Generic.giveName', { object: t('Generic.plan') })}
      </DialogHeader>
      <input
        autoFocus
        className={'form-control'}
        name={'input'}
        onChange={e => setInput(e.target.value)}
        onKeyDown={e =>
          e.key === 'Enter' &&
          submitHandler({ assignedOperator: operator, name: input })
        }
        placeholder={t('TrainingPlans.planNamePlaceholder')}
        ref={ref}
        value={input}
      />
      {!input.length && (
        <span className="error-w-icon">{`${t('Generic.planName')} ${t(
          'TrainingPlans.requiredValue',
        )}`}</span>
      )}
    </>
  );

  const stepsData = {
    1: {
      labelButtonDeny: t('Button.cancel'),
      labelButtonNext: t('Button.next'),
      onClickBack: () => {
        closePopup();
        reopenTemplates();
      },
      onClickNext: () => {
        stepWizard.nextStep();
      },
      isDisabledButtonNext: !operatorSelected,
      ContentData: Step1,
    },
    2: {
      labelButtonDeny: isMultiOperator ? t('Button.back') : t('Button.cancel'),
      labelButtonNext: t('Button.create'),
      onClickBack: () => {
        isMultiOperator ? stepWizard.previousStep() : closePopup();
      },
      onClickNext: () => {
        submitHandler({ assignedOperator: operator, name: input });
      },
      isDisabledButtonNext: !input,
      ContentData: Step2,
    },
  };

  const step = stepsData[stepIndex];

  return (
    <StepWizardPopUp
      closePopup={closePopup}
      initialStep={initialStep}
      open={isOpen}
      setStepIndex={setStepIndex}
      setStepWizard={setStepWizard}
      step={step}
      steps={stepsData}
    />
  );
};

const DialogHeader = styled(Typography)`
  margin-bottom: 16px;
`;

CopyTemplatePopup.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  operators: PropTypes.array,
  planName: PropTypes.string,
  submitHandler: PropTypes.func.isRequired,
  reopenTemplates: PropTypes.func.isRequired,
};

CopyTemplatePopup.defaultProps = {
  planName: '',
  operators: [],
};

export default CopyTemplatePopup;
