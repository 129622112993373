import { isDocumentPath } from 'util/url';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { isTridionDocument } from '../../../store/document/selectors';

const MainContent = ({ children }) => {
  const location = useLocation();
  const isDocument = isDocumentPath(location);
  const isTridion = useSelector(isTridionDocument);
  const isTridionDoc = isDocument && isTridion;

  return (
    <MainContentContainer
      $isDocument={isDocument}
      $isTridionDocument={isTridionDoc}
    >
      {children}
    </MainContentContainer>
  );
};

MainContent.propTypes = {
  children: PropTypes.node,
};

MainContent.defaultProps = {
  children: null,
};

const MainContentContainer = styled.div`
  width: 100%;
  height: auto;
  max-width: ${({ $isDocument }) => ($isDocument ? '100%' : '1152px')};
  flex-grow: 1;
  margin: 0 auto;
  padding: ${({ $isDocument }) => ($isDocument ? '0' : '15px')};
  display: flex;
  flex-direction: column;
  background: ${({ $isTridionDocument, theme }) =>
    $isTridionDocument && theme.primaryPalette.documentBackground};
`;

export default MainContent;
