import { createSlice } from '@reduxjs/toolkit';

export const slice = createSlice({
  name: 'licensee',
  initialState: {
    plansToggleLicensee: false,
  },
  reducers: {
    setTogglePlansLicensee: state => {
      state.plansToggleLicensee = true;
    },
    setTogglePlansMyRestaurants: state => {
      state.plansToggleLicensee = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setTogglePlansLicensee, setTogglePlansMyRestaurants } =
  slice.actions;

export default slice.reducer;
