import Constants from 'constants/index';
import Bugsnag from '@bugsnag/js';
import { formatBugsnagErrorMessage } from 'bugsnag';
import { useCallback, useEffect, useState } from 'react';
import { useUpdateComplianceHeartbeatMutation } from 'services/pathwayApi';
import { useSelector } from 'react-redux';
import { selectUserId } from 'store/user/selectors';

const useComplianceTimer = (courseId, userIds) => {
  const [updateComplianceHeartbeat] = useUpdateComplianceHeartbeatMutation();
  const [timerIsRunning, setTimerIsRunning] = useState(false);
  const [roundUpTimer, setRoundUpTimer] = useState(false);

  const userId = useSelector(selectUserId);

  // eslint-disable-next-line no-shadow
  const mapUserIds = userIds => {
    if (userIds === userId) {
      return undefined;
    }
    return Array.isArray(userIds) ? userIds : [userIds];
  };

  const logTimeViewed = useCallback(
    milliSeconds => {
      const seconds = milliSeconds / 1000;
      updateComplianceHeartbeat({
        courseId: courseId,
        userIds: mapUserIds(userIds),
        interval: seconds,
      })
        .unwrap()
        .then()
        .catch(err => {
          Bugsnag.notify(formatBugsnagErrorMessage(err));
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [courseId],
  );

  useEffect(() => {
    let logIntervalId = null;
    let roundIntervalId = null;

    if (timerIsRunning && courseId) {
      logIntervalId = setInterval(() => {
        setRoundUpTimer(prevState => !prevState);
      }, Constants.VIEW_TIME_DEFAULTS.POLL_TIME_ROUNDUP);
      roundIntervalId = setInterval(() => {
        logTimeViewed(Constants.VIEW_TIME_DEFAULTS.POLL_TIME_DEFAULT);
      }, Constants.VIEW_TIME_DEFAULTS.POLL_TIME_DEFAULT);
    }
    return () => {
      clearInterval(logIntervalId);
      clearInterval(roundIntervalId);
    };
  }, [courseId, logTimeViewed, timerIsRunning]);

  useEffect(() => {
    if (roundUpTimer && !timerIsRunning && courseId && userIds) {
      logTimeViewed(Constants.VIEW_TIME_DEFAULTS.POLL_TIME_DEFAULT);
      setRoundUpTimer(false);
    }
  }, [logTimeViewed, courseId, roundUpTimer, timerIsRunning, userIds]);

  useEffect(() => {
    return () => {
      setTimerIsRunning(false);
    };
  }, []);

  const startTimer = useCallback(() => {
    setTimerIsRunning(true);
  }, []);

  const stopTimer = useCallback(() => {
    setTimerIsRunning(false);
  }, []);

  return [startTimer, stopTimer];
};

export default useComplianceTimer;
