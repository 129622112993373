import { getNameFromLanguage } from 'util/language';
import { mapCategoryToAliasTranslation } from 'util/categoryUtils';
import { generateProgressText } from 'util/generateProgressText';
import { getProgressBarText } from 'util/generateProgressBarText';
import Constants from 'constants/index';
import { getTimeSpentOnPlan } from 'util/time';
import { getDueDateColor, getDueDateString } from 'util/dueDate';
import PropTypes from 'prop-types';
import PlanCard from 'components/PlanCard/PlanCard';
import { useTranslation } from 'react-i18next';
import { Ribbon } from 'icons';
import { IconAlertTriangleFilled } from '@tabler/icons-react';
import theme from 'styles/theme';
import styled from 'styled-components';
import PlanCardIcon from 'components/PlanCard/PlanCardComponents/PlanCardIcon';
import PlanCardTag from 'components/PlanCard/PlanCardComponents/PlanCardTag';
import PlanCardContent from 'components/PlanCard/PlanCardContent/PlanCardContent';
import PlanCardTitle from '../PlanCardComponents/PlanCardTitle';
import PlanCardLabel from '../PlanCardComponents/PlanCardLabel';
import PlanCardSubHeader from '../PlanCardComponents/PlanCardSubHeader';

const MyPlansCompliancePlanCard = ({
  className,
  'data-testid': dataTestId,
  course,
}) => {
  const { t } = useTranslation();
  const timeSpentOnPlan = getTimeSpentOnPlan(course, true);
  const isComplete =
    course?.status === Constants.LEARN_UPON_TRAINING_PLANS.COMPLETED ||
    course?.status === Constants.LEARN_UPON_TRAINING_PLANS.PASSED;
  const isFailed =
    course?.status === Constants.LEARN_UPON_TRAINING_PLANS.FAILED;
  const finalScore = course?.finalScore;
  const isCompliance = true;
  const isFoodSafety = getNameFromLanguage(course?.courseName)?.includes(
    Constants.FOOD_SAFETY_COURSE_NAME,
  );
  const percentComplete = course?.percentComplete;

  return (
    <>
      {!!course && (
        <>
          <PlanCard
            category={Constants.PLAN_CATEGORIES.COMPLIANCE}
            className={className}
            data-testid={dataTestId}
            link={`/${Constants.ROUTE_PATH_NAMES.COMPLIANCE_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.DOCUMENT_PATH_NAME}/${course?.pathwayCourseId}/true`}
            progress={!!isComplete || !!isFailed ? null : percentComplete}
          >
            <PlanCardContent>
              <PlanCardLabel>
                {t(
                  mapCategoryToAliasTranslation(
                    Constants.PLAN_CATEGORIES.COMPLIANCE,
                  ),
                )}
              </PlanCardLabel>
              <PlanCardTitle>
                {getNameFromLanguage(course?.courseName)}
              </PlanCardTitle>
              {!!isComplete && (
                <>
                  <PlanCardSubHeader
                    className="success"
                    data-testid="PlanTaskCount"
                  >
                    {generateProgressText({
                      finalScore,
                      isCompliance,
                      isComplete,
                      isFailed,
                      isFoodSafety,
                      timeSpentOnPlan,
                    })}
                  </PlanCardSubHeader>
                  <PlanCardSubHeader className="bold">
                    {course?.certificate?.expiration ? (
                      t('TrainingPlans.certificationExpires', {
                        expirationDate: new Date(
                          course?.certificate?.expiration,
                        ).toLocaleDateString(),
                      })
                    ) : (
                      <>
                        <FailedIconAlertTriangleFilled />
                        <span style={{ marginLeft: 4 }}>
                          {t('TrainingPlans.noExpirationDate')}
                        </span>
                      </>
                    )}
                  </PlanCardSubHeader>
                </>
              )}
              {!!isFailed && (
                <PlanCardSubHeader className="bold" data-testid="PlanTaskCount">
                  {generateProgressText({
                    finalScore,
                    isCompliance,
                    isComplete,
                    isFailed,
                    isFoodSafety,
                    timeSpentOnPlan,
                  })}
                </PlanCardSubHeader>
              )}
              {!isComplete && !isFailed && (
                <PlanCardSubHeader data-testid="PlanTaskCount">
                  {getProgressBarText({
                    isCompliance,
                    isComplete,
                    percentComplete,
                    timeSpentOnPlan,
                  })}
                </PlanCardSubHeader>
              )}
            </PlanCardContent>
            {!isComplete && !!course?.dueDate && (
              <PlanCardTag
                color={getDueDateColor(course?.dueDate)}
                data-testid="DateTag"
                text={getDueDateString(course?.dueDate)}
                tooltip={t('TrainingPlans.accountability.due.dueDate', {
                  dueDate: new Date(course?.dueDate).toLocaleDateString(),
                })}
              />
            )}
            {!!isComplete && (
              <PlanCardIcon
                icon={<CompleteRibbon />}
                tooltip={
                  <>
                    <strong>
                      {t('TrainingPlans.statusOptions.completed')}:
                    </strong>
                    {new Date(course?.completedDate).toLocaleDateString()}
                  </>
                }
              />
            )}
          </PlanCard>
        </>
      )}
    </>
  );
};

MyPlansCompliancePlanCard.propTypes = {
  className: PropTypes.string,
  'data-testid': PropTypes.string,
  course: PropTypes.shape({
    certificate: PropTypes.object,
    courseName: PropTypes.object,
    createdDate: PropTypes.string,
    enabled: PropTypes.bool,
    id: PropTypes.string,
    completedDate: PropTypes.string,
    dueDate: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    duration: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    enrolledDate: PropTypes.string,
    enrollmentId: PropTypes.string,
    finalScore: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    hidden: PropTypes.bool,
    mostRecentCompletedDate: PropTypes.string,
    pathwayCourseId: PropTypes.string,
    percentComplete: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    startedDate: PropTypes.string,
    status: PropTypes.string,
  }).isRequired,
};

MyPlansCompliancePlanCard.defaultProps = {
  className: '',
  'data-testid': 'MyCompliancePlan',
};

const CompleteRibbon = styled(Ribbon)`
  height: 28px;
  width: 28px;
`;
export const FailedIconAlertTriangleFilled = styled(IconAlertTriangleFilled)`
  height: 16px;
  width: 16px;
  color: ${() => theme.semanticColors.warning};
`;

export default MyPlansCompliancePlanCard;
