import Constants from 'constants';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import useDocumentCookieRefresh from 'hooks/useDocumentCookieRefresh';
import { useFlags } from 'launchdarkly-react-client-sdk';

const DocumentPreview = ({ fileId, versionId, contentApiUrl }) => {
  const { xpApi: xpApiFeatureFlag } = useFlags();

  const contentUrl =
    xpApiFeatureFlag && contentApiUrl
      ? contentApiUrl
      : `${Constants.PATHWAY_API_CONTENT_URL}/${versionId}/${fileId}/index.html`;

  const { isSuccess: isDocumentCookieSuccess } = useDocumentCookieRefresh();

  return (
    <>
      {isDocumentCookieSuccess && (
        <StyledIFrame
          allow="fullscreen"
          className="content-iframe"
          src={contentUrl}
        />
      )}
    </>
  );
};

const StyledIFrame = styled.iframe`
  width: 100%;
  height: 100%;
`;

DocumentPreview.propTypes = {
  versionId: PropTypes.string,
  fileId: PropTypes.string,
  contentApiUrl: PropTypes.string,
};
DocumentPreview.defaultProps = {
  versionId: null,
  fileId: null,
  contentApiUrl: null,
};

export default DocumentPreview;
