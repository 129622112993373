import i18next from 'i18next';
import FailedAlertMessage from 'sharedComponents/app/FailedAlertMessage';

export const generateProgressText = ({
  completedStepCount = null,
  finalScore = false,
  isCompliance = false,
  isComplete,
  isFailed = false,
  isFoodSafety = false,
  timeSpentOnPlan,
  stepCount = null,
}) => {
  if (isCompliance) {
    const result = getComplianceProgress({
      finalScore,
      isComplete,
      isFailed,
      isFoodSafety,
      timeSpentOnPlan,
    });
    return result;
  } else if (!!isComplete) {
    return `${i18next.t('Generic.planCompletedIn')} ${timeSpentOnPlan}`;
  } else {
    return `${completedStepCount}/${stepCount} ${i18next.t(
      'Generic.itemsCompleted',
    )}`;
  }
};

const getFoodSafetyCompletedText = ({ finalScore, timeSpentOnPlan }) => {
  if (!!finalScore && !!timeSpentOnPlan) {
    // User has has score + time: Completed in X mins with a score of X%
    return `${i18next.t('Generic.completedIn')} ${timeSpentOnPlan} ${i18next.t(
      'TrainingPlans.accountability.withScore',
      { finalScore: finalScore },
    )}`;
  } else if (!finalScore && !!timeSpentOnPlan) {
    // User has no score, but does have time: Completed in X mins. Score is not available.
    return `${i18next.t('Generic.completedIn')} ${timeSpentOnPlan}. ${i18next.t(
      'Generic.scoreNotAvailable',
    )}.`;
  } else if (!!finalScore && !timeSpentOnPlan) {
    // User has score but time=null: Completed with a score of X%. Time spent is not available.
    return `${i18next.t('TrainingPlans.accountability.completedWithScore', {
      finalScore: finalScore,
    })}. ${i18next.t('Generic.timeSpentNotAvailable')}.`;
  } else {
    // User has no score or time: Completed. Score and time spent are not available.
    return `${i18next.t('Generic.completed')}. ${i18next.t(
      'Generic.scoreAndTimeNotAvailable',
    )}.`;
  }
};

const getFoodSafetyFailedText = ({ finalScore, timeSpentOnPlan }) => {
  if (!!finalScore) {
    if (!!timeSpentOnPlan) {
      // User has has score + time: [icon] Exam not passed yet · X mins spent · Last score was X%
      return (
        <FailedAlertMessage>
          <>
            <span> &middot; </span>
            {i18next.t('Generic.minsSpent', {
              duration: timeSpentOnPlan,
            })}
          </>
          <>
            <span> &middot; </span>
            {i18next.t('Generic.lastScore', {
              score: finalScore,
            })}
          </>
        </FailedAlertMessage>
      );
    } else {
      // User has score but time=null: [icon] Exam not passed yet · Time spent is not available · Last score was X%
      return (
        <FailedAlertMessage>
          <>
            <span> &middot; </span>
            {i18next.t('Generic.timeSpentNotAvailable')}
          </>
          <>
            <span> &middot; </span>
            {i18next.t('Generic.lastScore', {
              score: finalScore,
            })}
          </>
        </FailedAlertMessage>
      );
    }
  } else if (timeSpentOnPlan) {
    // User has no score, but does have time: [icon] Exam not passed yet · X mins spent · Score is not available
    return (
      <FailedAlertMessage>
        <>
          <span> &middot; </span>
          {i18next.t('Generic.minsSpent2', { timeSpent: timeSpentOnPlan })}
        </>
        <>
          <span> &middot; </span>
          {i18next.t('Generic.scoreNotAvailable')}
        </>
      </FailedAlertMessage>
    );
  } else {
    // User has no score or time: [icon] Exam not passed yet · Score and time spent are not available
    return (
      <FailedAlertMessage>
        <>
          <span> &middot; </span>
          {i18next.t('Generic.scoreAndTimeNotAvailable')}
        </>
      </FailedAlertMessage>
    );
  }
};

const getOtherComplianceText = ({ isComplete, timeSpentOnPlan }) => {
  if (!!isComplete) {
    if (!!timeSpentOnPlan) {
      // User has time: Completed in X mins
      return `${i18next.t('Generic.completedIn')} ${timeSpentOnPlan}`;
    } else {
      // User time=null: Completed. Time spent is not available.
      return `${i18next.t('Generic.completed')}. ${i18next.t(
        'Generic.timeSpentNotAvailable',
      )}.`;
    }
  } else {
    if (!!timeSpentOnPlan) {
      // User has time: Completed in X mins
      return (
        <FailedAlertMessage>
          <>
            <span> &middot; </span>
            {i18next.t('Generic.minsSpent2', { timeSpent: timeSpentOnPlan })}
          </>
        </FailedAlertMessage>
      );
    } else {
      return (
        <FailedAlertMessage>
          <span> &middot; </span>
          {i18next.t('Generic.timeSpentNotAvailable')}
        </FailedAlertMessage>
      );
    }
  }
};

const getComplianceProgress = ({
  finalScore,
  isComplete,
  isFailed,
  isFoodSafety,
  timeSpentOnPlan,
}) => {
  // Compliance
  if (isFoodSafety) {
    // Food Safety
    if (!!isComplete) {
      return getFoodSafetyCompletedText({
        finalScore,
        timeSpentOnPlan,
      });
    } else if (!!isFailed) {
      return getFoodSafetyFailedText({
        finalScore,
        timeSpentOnPlan,
      });
    }
  } else {
    // PCI
    return getOtherComplianceText({
      isComplete,
      timeSpentOnPlan,
    });
  }
};
