import { getNameFromLanguage } from 'util/language';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import InputDialog from 'components/Dialogs/InputDialog';

const RenameQuizPopup = ({
  showRenameQuizPopup,
  setShowRenameQuizPopup,
  onRenameQuizAndClosePopup,
  quizDetails,
}) => {
  const { t } = useTranslation();

  return (
    <InputDialog
      headerText={t('Generic.giveNewName', { object: t('Generic.quiz') })}
      isOpen={showRenameQuizPopup}
      keyDownEnter={true}
      onClose={() => setShowRenameQuizPopup(false)}
      primaryButtonHandler={onRenameQuizAndClosePopup}
      primaryButtonText={t('Button.update')}
      secondaryButtonHandler={() => setShowRenameQuizPopup(false)}
      secondaryButtonText={t('Button.cancel')}
      Text={t('TrainingPlans.buildQuizzes.changeQuizName')}
      textInputDefaultValue={getNameFromLanguage(quizDetails?.name)}
    />
  );
};

RenameQuizPopup.propTypes = {
  showRenameQuizPopup: PropTypes.bool.isRequired,
  setShowRenameQuizPopup: PropTypes.func.isRequired,
  onRenameQuizAndClosePopup: PropTypes.func.isRequired,
  quizDetails: PropTypes.object.isRequired,
};

export default RenameQuizPopup;
