import { isApiError } from 'util/request';
import { getNameFromLanguage } from 'util/language';
import { mapCategoryToColor } from 'util/categoryUtils';
import Constants from 'constants';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Trans, useTranslation } from 'react-i18next';
import Bugsnag from '@bugsnag/browser';
import * as PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import { QuizDefault } from 'icons';
import {
  useGetQuizzesQuery,
  useUpdateTrainingPlanMutation,
} from 'services/pathwayApi';
import { setBuildQuizzes, loadMoreQuizzes } from 'store/quizzes/slice';
import {
  selectPaginatedQuizzes,
  selectPagination,
} from 'store/quizzes/selector';
import LoadMorePaginator from 'components/LoadMorePaginator/LoadMorePaginator';
import LoadingOverlay from 'sharedComponents/app/LoadingOverlay';
import { formatBugsnagErrorMessage } from 'bugsnag';
import GenericError from 'sharedComponents/app/GenericError';
import {
  Button,
  Card,
  CardContent,
  Modal,
  ModalBody,
  ModalHeader,
  Typography,
  useBreakpoints,
  useMediaQuery,
} from 'cfa-react-components';
import { NoMessage } from 'containers/TrainingPlans/ManagePlans/ManagePlanView';

const QuizLibraryCard = ({
  quiz,
  onAddQuiz,
  onPreviewQuiz,
  quizNameTranslator,
  quizAddButtonTestId,
  quizPreviewButtonTestId,
  quizNameTestId,
  addingDisabled,
  previewDisabled,
}) => {
  const { t } = useTranslation();
  const breakpoints = useBreakpoints();
  const isSmAndUp = useMediaQuery(breakpoints.up('sm'));
  const quizName = quizNameTranslator(quiz?.name);

  return (
    <StyledQuizCard>
      <QuizCardImageWrapper $backgroundColor={mapCategoryToColor('default')}>
        <QuizDefault />
      </QuizCardImageWrapper>
      <QuizCardContent $isSmAndUp={isSmAndUp}>
        <StyledQuizLibraryCardLeft>
          <StyledQuizLibraryCardName data-testid={quizNameTestId}>
            {quizName}
          </StyledQuizLibraryCardName>
        </StyledQuizLibraryCardLeft>
        <StyledQuizLibraryCardRight $isSmAndUp={isSmAndUp}>
          <StyledQuizButton
            color="secondary"
            data-testid={quizPreviewButtonTestId}
            disabled={previewDisabled}
            onClick={() => onPreviewQuiz(quiz)}
            variant="outlined"
          >
            {t('Button.preview')}
          </StyledQuizButton>
          <StyledQuizButton
            color="secondary"
            data-testid={quizAddButtonTestId}
            disabled={addingDisabled}
            onClick={() => onAddQuiz(quiz)}
            variant="filled"
          >
            {t('Button.add')}
          </StyledQuizButton>
        </StyledQuizLibraryCardRight>
      </QuizCardContent>
    </StyledQuizCard>
  );
};

const QuizLibraryPopUp = ({
  isOpen,
  onClose,
  sectionId,
  refetch,
  trainingPlan,
}) => {
  const { t } = useTranslation();
  const { showing, total } = useSelector(selectPagination);
  const paginatedQuizzes = useSelector(selectPaginatedQuizzes);
  const dispatch = useDispatch();

  const {
    data: quizzes,
    isFetching: isFetchingQuizzes,
    errorGettingQuizzes,
  } = useGetQuizzesQuery();

  useEffect(() => {
    dispatch(setBuildQuizzes({ quizzes: quizzes ?? [] }));
  }, [dispatch, quizzes]);

  const [updateTrainingPlan] = useUpdateTrainingPlanMutation();

  if (isApiError(errorGettingQuizzes)) {
    Constants.BUGSNAG_ENABLED &&
      Bugsnag.notify(formatBugsnagErrorMessage(errorGettingQuizzes));
    return <GenericError />;
  }

  const stepType = Constants.STEP_TYPES.QUIZ;

  const withinSection = !!sectionId?.length;

  const getQuizStep = quiz => ({
    id: uuidv4(),
    name: {
      en: quiz.name?.en ?? quiz.name?.es,
      es: quiz.name?.es ?? quiz.name?.en,
    },
    type: stepType,
    quizId: quiz.typeFormId,
    reference: null,
    note: null,
    urls: [],
    available: true,
  });

  const updatePlanAndRefetchThenRunOnClose = payload => {
    updateTrainingPlan(payload)
      .unwrap()
      .then(() => {
        refetch();
        onClose({ withPreview: false });
      })
      .catch(err => {
        Bugsnag.notify(formatBugsnagErrorMessage(err));
      });
  };

  const buildPayloadForPlanUpdateWithQuiz = ({ quizStep, newSteps }) => ({
    locations: trainingPlan.locations,
    checklist: withinSection
      ? {
          ...trainingPlan,
          sections: [
            ...trainingPlan.sections.map(section =>
              section.id === sectionId
                ? {
                    ...section,
                    steps: [...section.steps, quizStep],
                  }
                : section,
            ),
          ],
        }
      : {
          ...trainingPlan,
          steps: newSteps,
        },
  });

  const onAddQuiz = quiz => {
    const quizStep = getQuizStep(quiz);
    const newSteps = [...trainingPlan.steps, quizStep];
    const payload = buildPayloadForPlanUpdateWithQuiz({ quizStep, newSteps });
    updatePlanAndRefetchThenRunOnClose(payload);
  };

  return (
    <>
      <LoadingOverlay isOpen={isFetchingQuizzes} />
      <StyledModal
        onClose={onClose}
        scrollMode="modal-body"
        show={isOpen}
        size="lg"
      >
        <ModalHeader>{t('TrainingPlans.addQuiz.title')}</ModalHeader>
        <ModalBody>
          <LoadingOverlay isOpen={isFetchingQuizzes} />
          {!!paginatedQuizzes?.length && !isFetchingQuizzes && (
            <>
              {paginatedQuizzes?.map((quiz, index) => {
                return (
                  <QuizLibraryCard
                    key={index}
                    onAddQuiz={onAddQuiz}
                    onPreviewQuiz={quizToPreview =>
                      onClose({ withPreview: true, quiz: quizToPreview })
                    }
                    quiz={quiz}
                    quizAddButtonTestId="QuizLibraryAddButton"
                    quizNameTestId="QuizLibraryName"
                  />
                );
              })}
              <LoadMorePaginator
                onClick={() => dispatch(loadMoreQuizzes())}
                showing={showing}
                showingText={t('TrainingPlans.showingXOfYQuizzes', {
                  showing,
                  total,
                })}
                total={total}
              />
            </>
          )}
          {!paginatedQuizzes?.length && !isFetchingQuizzes && (
            <NoMessage
              message={
                <Trans i18nKey={'TrainingPlans.buildQuizzes.noQuizzesPopup'} />
              }
            />
          )}
        </ModalBody>
      </StyledModal>
    </>
  );
};

const StyledQuizCard = styled(Card)`
  animation: fadeIn linear 0.3s;
  margin: 8px 0;
  flex-direction: row;
  padding: 0;
  height: auto;
  position: relative;
  overflow: visible;
`;

const QuizCardImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: auto;
  background: ${props => props.$backgroundColor};
  flex-shrink: 0;
  border-top-left-radius: inherit;
  border-bottom-left-radius: inherit;
  padding: 1.5rem 1rem;
`;

const QuizCardContent = styled(CardContent)`
  display: flex;
  flex-direction: ${({ $isSmAndUp }) => ($isSmAndUp ? 'row' : 'column')};
  align-items: ${({ $isSmAndUp }) => ($isSmAndUp ? 'center' : 'flex-start')};
  justify-content: space-between;
  width: 100%;
  padding: 16px !important;
`;

const StyledQuizLibraryCardLeft = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  flex-grow: 1;
  max-width: 100%;
  overflow: hidden;
  margin-right: 1rem;
`;

const StyledQuizLibraryCardRight = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: ${({ $isSmAndUp }) => ($isSmAndUp ? '0' : '1rem')};
  gap: 1rem;
  flex-direction: row;
  width: ${({ $isSmAndUp }) => ($isSmAndUp ? 'auto' : '100%')};
`;

const StyledModal = styled(Modal)`
  .cfa-modal-header {
    padding-bottom: 2rem;
  }
`;

const StyledQuizLibraryCardName = styled(Typography)`
  font-weight: 500;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  min-width: 0;
  max-width: 100%;
`;

const StyledQuizButton = styled(Button)`
  min-width: unset;
`;

QuizLibraryCard.defaultProps = {
  quizNameTranslator: getNameFromLanguage,
  addingDisabled: false,
  previewDisabled: false,
  quizAddButtonTestId: 'quizAddButtonTest',
  quizPreviewButtonTestId: 'quizPreviewButtonTest',
  quizNameTestId: 'quizNameTest',
};

QuizLibraryPopUp.defaultProps = {
  sectionId: '',
  refetch: () => {},
  trainingPlan: null,
};

QuizLibraryCard.propTypes = {
  quiz: PropTypes.object.isRequired,
  onAddQuiz: PropTypes.func.isRequired,
  onPreviewQuiz: PropTypes.func.isRequired,
  quizNameTranslator: PropTypes.func,
  quizAddButtonTestId: PropTypes.string,
  quizPreviewButtonTestId: PropTypes.string,
  quizNameTestId: PropTypes.string,
  addingDisabled: PropTypes.bool,
  previewDisabled: PropTypes.bool,
};

QuizLibraryPopUp.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  sectionId: PropTypes.string,
  refetch: PropTypes.func,
  trainingPlan: PropTypes.object,
};

export default QuizLibraryPopUp;
