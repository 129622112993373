import { Typography } from 'cfa-react-components';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const PlanCardSubHeader = ({
  children,
  className,
  'data-testid': dataTestId,
}) => {
  return (
    <TypographySubheader
      className={className}
      data-testid={dataTestId}
      variant="body2"
    >
      {children}
    </TypographySubheader>
  );
};

PlanCardSubHeader.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.node,
  ]).isRequired,
  className: PropTypes.string,
  'data-testid': PropTypes.string,
};

PlanCardSubHeader.defaultProps = {
  className: '',
  'data-testid': 'PlanCardSubHeader',
};

const TypographySubheader = styled(Typography)`
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 0;
  max-width: 100%;
  overflow-wrap: break-word;
  &.bold {
    font-weight: 700;
  }
  &.success {
    color: ${({ theme }) => theme.semanticColors.success};
    font-weight: 700;
  }
`;
export default PlanCardSubHeader;
