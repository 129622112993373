export const capitalizeFirstLetter = string => {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};

export const capitalizeWords = (string, acronym) => {
  // CFA_SUPPLY --> CFA Supply
  if (acronym) {
    const words = string.split('_');
    return (
      words[0] +
      ' ' +
      words[1].charAt(0).toUpperCase() +
      words[1].slice(1).toLowerCase()
    );
    // BAY_CENTER --> Bay Center
  } else {
    return string
      .split('_')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  }
};
