import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Divider,
  Typography,
  Checkbox,
} from 'cfa-react-components';
import { useTranslation } from 'react-i18next';
import { IconAlertOctagonFilled } from '@tabler/icons-react';

const PrintReportModal = ({
  bodyText,
  children,
  handleShowDoBCheckbox,
  headerText,
  isFoodSafety,
  isOpen,
  noLocationsSelected,
  onClose,
  primaryButtonColor,
  primaryButtonHandler,
  primaryButtonText,
  primaryButtonVariant,
  secondaryButtonColor,
  secondaryButtonHandler,
  secondaryButtonText,
  selectedReportLocations,
  showDoBChecked,
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      data-testid="PrintReportModal"
      onClose={onClose}
      show={isOpen}
      size="sm"
    >
      <>
        <StyledModalHeader data-testid="PrintReportModalHeaderText">
          {headerText}
        </StyledModalHeader>
        <ModalBody dangerouslySetInnerHTML={{ __html: bodyText }} />
        <ChildrenWrapper>
          <StyledDivider orientation="horizontal" variant="fullLength" />
          {children && (
            <>
              <StyledTypography variant={'body1'}>
                {t('Generic.locations')}
              </StyledTypography>
              {children}
              {!!noLocationsSelected && !selectedReportLocations.length && (
                <NoLocationWarning color="primary" variant="caption1">
                  <StyledErrorIcon />
                  {t('Generic.selectAtLeastOne')}
                </NoLocationWarning>
              )}
            </>
          )}
        </ChildrenWrapper>
        <OptionsWrapper>
          {!!isFoodSafety && (
            <>
              <OptionsTypography variant={'body1'}>
                {t('Generic.options')}
              </OptionsTypography>
              <Checkbox
                checked={showDoBChecked}
                label={t('Generic.showDoB')}
                onChange={handleShowDoBCheckbox}
              />
            </>
          )}
          <StyledDivider orientation="horizontal" variant="fullLength" />
        </OptionsWrapper>
        <StyledModalFooter>
          {secondaryButtonHandler && secondaryButtonText && (
            <CancelButton
              color={secondaryButtonColor}
              data-testid="ConfirmationPopUpSecondaryButton"
              onClick={secondaryButtonHandler}
              variant="outlined"
            >
              {secondaryButtonText}
            </CancelButton>
          )}
          <StyledButton
            color={primaryButtonColor}
            data-testid="ConfirmationPopUpPrimaryButton"
            onClick={primaryButtonHandler}
            variant={primaryButtonVariant}
          >
            {primaryButtonText}
          </StyledButton>
        </StyledModalFooter>
      </>
    </Modal>
  );
};

const StyledModalHeader = styled(ModalHeader)`
  font-size: 24px;
`;

const ChildrenWrapper = styled.div`
  padding: 0 var(--cfa-modal-padding-x);
`;

const OptionsWrapper = styled.div`
  padding: 0 var(--cfa-modal-padding-x);
`;

const StyledTypography = styled(Typography)`
  font-weight: 700;
  padding-bottom: 1em;
`;

const OptionsTypography = styled(Typography)`
  font-weight: 700;
  padding: 1em 0;
`;

const StyledDivider = styled(Divider)`
  padding: 0 var(--cfa-modal-padding-x);
  margin: 24px 0;
`;

const NoLocationWarning = styled(Typography)`
  padding-top: 0.5em;
`;

const StyledErrorIcon = styled(IconAlertOctagonFilled)`
  margin-right: 8px;
  height: 16px;
  width: 16px;
`;

// important needed to override design system footer modal styles
const StyledModalFooter = styled(ModalFooter)`
  flex-direction: row !important;
  flex-flow: unset;
  padding-top: 0;
`;

const StyledButton = styled(Button)`
  min-width: 163px;
`;

const CancelButton = styled(Button)`
  min-width: 163px;
  margin-right: 0.5em;
`;

PrintReportModal.propTypes = {
  bodyText: PropTypes.string.isRequired,
  children: PropTypes.node,
  handleShowDoBCheckbox: PropTypes.func.isRequired,
  headerText: PropTypes.string.isRequired,
  isFoodSafety: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool.isRequired,
  noLocationsSelected: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  primaryButtonColor: PropTypes.string,
  primaryButtonHandler: PropTypes.func,
  primaryButtonText: PropTypes.string.isRequired,
  primaryButtonVariant: PropTypes.string,
  secondaryButtonHandler: PropTypes.func,
  secondaryButtonColor: PropTypes.string,
  secondaryButtonText: PropTypes.string,
  selectedReportLocations: PropTypes.array.isRequired,
  showDoBChecked: PropTypes.bool.isRequired,
};

PrintReportModal.defaultProps = {
  children: null,
  onClose: () => {},
  primaryButtonColor: 'secondary',
  primaryButtonHandler: () => {},
  primaryButtonVariant: 'filled',
  secondaryButtonColor: 'secondary',
  secondaryButtonHandler: () => {},
  secondaryButtonText: '',
};

export default PrintReportModal;
