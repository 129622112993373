import { createSelector } from 'reselect';

export const licenseeLocationsFilterState = state =>
  state.licenseeLocationsFilter;
export const selectFilters = createSelector(
  licenseeLocationsFilterState,
  state => [...state.statusFilters, ...state.locationFilters],
);

export const selectSort = createSelector(
  licenseeLocationsFilterState,
  state => state.sort,
);

export const selectSortedAndFilteredAndPaginatedLicenseeLocations =
  createSelector(
    licenseeLocationsFilterState,
    state => state.paginatedLicenseeLocations,
  );

export const selectPagination = createSelector(
  licenseeLocationsFilterState,
  state => ({
    showing: state.showing,
    total: state.total,
  }),
);

export const selectAllLicenseeLocations = createSelector(
  licenseeLocationsFilterState,
  state => state.licenseeLocations,
);

export const selectSearchFilter = createSelector(
  licenseeLocationsFilterState,
  state => state.searchFilters,
);
