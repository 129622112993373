import PropTypes from 'prop-types';
import LicenseeLocationPicker from './LicenseeLocationPicker/LicenseeLocationPicker';

const LayoutWrapper = ({ children }) => {
  return (
    <>
      {children}
      <LicenseeLocationPicker />
    </>
  );
};

LayoutWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LayoutWrapper;
