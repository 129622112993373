import lazyComponent from 'util/lazyComponent';
import Constants from 'constants/index';
import { Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
// NOTE: only the routes that are erroring out most should be statically imported
// For other routes, use `lazyComponent()` for performance
import { SecureRoute } from '@okta/okta-react';
import SearchResultsPage from 'containers/SearchResults/SearchResultsPage';
import MyPlansTab from 'containers/TrainingPlans/MyPlans/MyPlansTab';
import BuildPlansTab from 'containers/TrainingPlans/BuildPlans/BuildPlansTab';
import ManagePlanView from 'containers/TrainingPlans/ManagePlans/ManagePlanView';
import EditPlanView from 'containers/TrainingPlans/BuildPlans/EditPlanView';
import AssignedPlanView from 'containers/TrainingPlans/MyPlans/AssignedPlanView';
import BuildQuizzesTab from 'containers/TrainingPlans/BuildQuizzes/BuildQuizzesTab';
import BuildQuizzesView from 'containers/TrainingPlans/BuildQuizzes/BuildQuizzesView';
import ManagePlansTab from 'containers/TrainingPlans/ManagePlans/ManagePlansTab';
import DocumentProxy from 'containers/TrainingPlans/MyPlans/DocumentProxy';
import PrintView from 'containers/PrintView/PrintView';
import { useIsNative } from 'hooks/useIsNative';
import { TrainingMode } from 'containers/TrainingPlans/TrainingMode';
import TeamMemberProgressView from 'containers/TrainingPlans/ManagePlans/TeamMemberProgressView';
import Admin from 'containers/Admin/Admin';
import SignalDocument from 'containers/Document/SignalDocument';
import { useFlags } from 'launchdarkly-react-client-sdk';
import ReportsPlansTab from 'containers/Reports/Plans/ReportsPlansTab';
import ReportsCompliancePlansTab from 'containers/Reports/CompliancePlans/ReportsCompliancePlansTab';
import ReportsTeamMembersTab from 'containers/Reports/TeamMembers/ReportsTeamMembersTab';
import ReportsTeamMembersView from 'containers/Reports/TeamMembers/ReportsTeamMembersView';
import ReportsPlansView from 'containers/Reports/Plans/ReportsPlansView';

const Placeholder = () => <span />;
// App.js has Bugsnag boundary that shows Generic error popup
const NotFoundSwitch = ({ children }) => (
  <Switch>
    {children}
    <Route path="*">
      {lazyComponent(() =>
        import(
          /* webpackChunkName: "route.location" */ './containers/404/404.js'
        ),
      )}
    </Route>
  </Switch>
);
NotFoundSwitch.propTypes = {
  children: PropTypes.node.isRequired,
};

// Native does not use the okta <Security> wrapper but checks for auth via useOktaAuth hook
const SecureRoutesHandledByNative = () => {
  const { ascendNav: featureflagAscendNav } = useFlags();
  return (
    <Suspense fallback={<Placeholder />}>
      <NotFoundSwitch>
        <Route exact path="/category/:categoryId">
          {lazyComponent(() =>
            import(
              /* webpackChunkName: "route.subcategories" */ './containers/Explore/Operations/SubCategoryPage'
            ),
          )}
        </Route>
        <Route exact path={`/${Constants.ROUTE_PATH_NAMES.PROFILE_PATH_NAME}`}>
          {lazyComponent(() =>
            import(
              /* webpackChunkName: "route.settings" */ './containers/Settings/SettingsPage'
            ),
          )}
        </Route>
        <Route exact path={`/${Constants.ROUTE_PATH_NAMES.SEARCH_PATH_NAME}`}>
          <SearchResultsPage />
        </Route>
        <Route exact path={`/${Constants.ROUTE_PATH_NAMES.TRAINING_PATH_NAME}`}>
          <MyPlansTab />
        </Route>
        <Route
          exact
          path={`/${Constants.ROUTE_PATH_NAMES.TRAINING_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.BUILD_PATH_NAME}`}
        >
          <BuildPlansTab />
        </Route>
        <Route
          exact
          path={`/${Constants.ROUTE_PATH_NAMES.TRAINING_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.PLAN_PATH_NAME}/:planId`}
        >
          <EditPlanView />
        </Route>
        <Route
          exact
          path={`/${Constants.ROUTE_PATH_NAMES.TRAINING_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.ASSIGNED_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.TO_COMPLETE_PATH_NAME}`}
        >
          <DocumentProxy />
        </Route>
        <Route
          exact
          path={`/${Constants.ROUTE_PATH_NAMES.TRAINING_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.ASSIGNED_PATH_NAME}/:planId`}
        >
          <AssignedPlanView />
        </Route>
        <Route
          exact
          path={`/${Constants.ROUTE_PATH_NAMES.TRAINING_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.TEAM_PATH_NAME}`}
        >
          <ManagePlansTab />
        </Route>
        <Route
          exact
          path={`/${Constants.ROUTE_PATH_NAMES.TRAINING_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.QUIZZES_PATH_NAME}`}
        >
          <BuildQuizzesTab />
        </Route>
        <Route
          exact
          path={`/${Constants.ROUTE_PATH_NAMES.TRAINING_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.QUIZZES_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.BUILD_PATH_NAME}`}
        >
          <BuildQuizzesView />
        </Route>
        <Route
          exact
          path={`/${Constants.ROUTE_PATH_NAMES.TRAINING_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.QUIZ_PATH_NAME}/:quizId`}
        >
          <BuildQuizzesView />
        </Route>
        <Route
          exact
          path={`/${Constants.ROUTE_PATH_NAMES.TRAINING_PATH_NAME}/:id/${Constants.ROUTE_PATH_NAMES.TEAM_MEMBERS_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.PROGRESS_PATH_NAME}/:teamMemberId`}
        >
          <TeamMemberProgressView />
        </Route>
        <Route
          exact
          path={`/${Constants.ROUTE_PATH_NAMES.REPORTS_PATH_NAME}/:id/${Constants.ROUTE_PATH_NAMES.TEAM_MEMBERS_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.PROGRESS_PATH_NAME}/:teamMemberId`}
        >
          <TeamMemberProgressView />
        </Route>
        <Route
          path={`/${Constants.ROUTE_PATH_NAMES.TRAINING_PATH_NAME}/:id/${Constants.ROUTE_PATH_NAMES.TEAM_MEMBERS_PATH_NAME}/:compliancePlan?`}
        >
          <ManagePlanView />
        </Route>
        <Route
          exact
          path={`/${Constants.ROUTE_PATH_NAMES.TRAINING_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.TRAINING_MODE_PATH_NAME}`}
        >
          <TrainingMode />
        </Route>
        <Route
          exact
          path={`/${Constants.ROUTE_PATH_NAMES.HEADLESS_PATH_NAME}/:documentId`}
        >
          <SignalDocument />
        </Route>
        <Route
          exact
          path={`/${Constants.ROUTE_PATH_NAMES.REPORTS_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.COMPLIANCE_PATH_NAME}`}
        >
          <ReportsCompliancePlansTab />
        </Route>
        <Route
          exact
          path={`/${Constants.ROUTE_PATH_NAMES.REPORTS_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.PLANS_PATH_NAME}`}
        >
          <ReportsPlansTab />
        </Route>
        <Route
          exact
          path={`/${Constants.ROUTE_PATH_NAMES.REPORTS_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.PLANS_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.PROGRESS_PATH_NAME}/:id/:compliancePlan?`}
        >
          <ReportsPlansView />
        </Route>
        <Route
          exact
          path={`/${Constants.ROUTE_PATH_NAMES.REPORTS_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.TEAM_MEMBERS_PATH_NAME}`}
        >
          <ReportsTeamMembersTab />
        </Route>
        <Route
          exact
          path={`/${Constants.ROUTE_PATH_NAMES.REPORTS_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.TEAM_MEMBERS_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.PROGRESS_PATH_NAME}/:id`}
        >
          <ReportsTeamMembersView />
        </Route>
        <Route
          exact
          path={`/${Constants.ROUTE_PATH_NAMES.DOCUMENT_PATH_NAME}/:documentId`}
        >
          {lazyComponent(() =>
            import(
              /* webpackChunkName: "route.doc/id" */ './containers/Document/Document'
            ),
          )}
        </Route>
        <Route
          exact
          path={`/${Constants.ROUTE_PATH_NAMES.LEADERSHIP_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.DOCUMENT_PATH_NAME}/:documentId/:isCompliance`}
        >
          {lazyComponent(() =>
            import(
              /* webpackChunkName: "route.doc/id" */ './containers/Document/Document'
            ),
          )}
        </Route>
        <Route
          exact
          path={`/${Constants.ROUTE_PATH_NAMES.COMPLIANCE_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.DOCUMENT_PATH_NAME}/:documentId/:isCompliance`}
        >
          {lazyComponent(() =>
            import(
              /* webpackChunkName: "route.doc/id" */ './containers/Document/Document'
            ),
          )}
        </Route>
        {featureflagAscendNav && (
          <Route
            exact
            path={`/${Constants.ROUTE_PATH_NAMES.LEADERSHIP_PATH_NAME}`}
          >
            {lazyComponent(() =>
              import(
                /* webpackChunkName: "route.leadership" */ './containers/Explore/Leadership/LeadershipTab'
              ),
            )}
          </Route>
        )}
        <Route exact path="/">
          {lazyComponent(() =>
            import(
              /* webpackChunkName: "route.categories" */ './containers/Explore/Operations/OperationsTab'
            ),
          )}
        </Route>
      </NotFoundSwitch>
    </Suspense>
  );
};

// Web uses the okta <Security> wrapper to check for auth
const SecureRouteHandledByWeb = () => {
  const { ascendNav: featureflagAscendNav } = useFlags();

  return (
    <Suspense fallback={<Placeholder />}>
      <NotFoundSwitch>
        <SecureRoute exact path="/category/:categoryId">
          {lazyComponent(() =>
            import(
              /* webpackChunkName: "route.subcategories" */ './containers/Explore/Operations/SubCategoryPage'
            ),
          )}
        </SecureRoute>
        <SecureRoute
          exact
          path={`/${Constants.ROUTE_PATH_NAMES.PROFILE_PATH_NAME}`}
        >
          {lazyComponent(() =>
            import(
              /* webpackChunkName: "route.settings" */ './containers/Settings/SettingsPage'
            ),
          )}
        </SecureRoute>
        <SecureRoute
          exact
          path={`/${Constants.ROUTE_PATH_NAMES.SEARCH_PATH_NAME}`}
        >
          <SearchResultsPage />
        </SecureRoute>
        <SecureRoute
          exact
          path={`/${Constants.ROUTE_PATH_NAMES.BINARY_PATH_NAME}/:documentId`}
        >
          <PrintView />
        </SecureRoute>
        <SecureRoute
          exact
          path={`/${Constants.ROUTE_PATH_NAMES.TRAINING_PATH_NAME}`}
        >
          <MyPlansTab />
        </SecureRoute>
        <SecureRoute
          exact
          path={`/${Constants.ROUTE_PATH_NAMES.TRAINING_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.BUILD_PATH_NAME}`}
        >
          <BuildPlansTab />
        </SecureRoute>
        <SecureRoute
          exact
          path={`/${Constants.ROUTE_PATH_NAMES.TRAINING_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.PLAN_PATH_NAME}/:planId`}
        >
          <EditPlanView />
        </SecureRoute>
        <SecureRoute
          exact
          path={`/${Constants.ROUTE_PATH_NAMES.TRAINING_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.ASSIGNED_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.TO_COMPLETE_PATH_NAME}`}
        >
          <DocumentProxy />
        </SecureRoute>
        <SecureRoute
          exact
          path={`/${Constants.ROUTE_PATH_NAMES.TRAINING_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.ASSIGNED_PATH_NAME}/:planId`}
        >
          <AssignedPlanView />
        </SecureRoute>
        <SecureRoute
          exact
          path={`/${Constants.ROUTE_PATH_NAMES.TRAINING_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.TEAM_PATH_NAME}`}
        >
          <ManagePlansTab />
        </SecureRoute>
        <SecureRoute
          exact
          path={`/${Constants.ROUTE_PATH_NAMES.TRAINING_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.QUIZZES_PATH_NAME}`}
        >
          <BuildQuizzesTab />
        </SecureRoute>
        <SecureRoute
          exact
          path={`/${Constants.ROUTE_PATH_NAMES.TRAINING_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.QUIZZES_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.BUILD_PATH_NAME}`}
        >
          <BuildQuizzesView />
        </SecureRoute>
        <SecureRoute
          exact
          path={`/${Constants.ROUTE_PATH_NAMES.TRAINING_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.QUIZ_PATH_NAME}/:quizId`}
        >
          <BuildQuizzesView />
        </SecureRoute>
        <SecureRoute
          exact
          path={`/${Constants.ROUTE_PATH_NAMES.TRAINING_PATH_NAME}/:id/${Constants.ROUTE_PATH_NAMES.TEAM_MEMBERS_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.PROGRESS_PATH_NAME}/:teamMemberId`}
        >
          <TeamMemberProgressView />
        </SecureRoute>
        <SecureRoute
          exact
          path={`/${Constants.ROUTE_PATH_NAMES.REPORTS_PATH_NAME}/:id/${Constants.ROUTE_PATH_NAMES.TEAM_MEMBERS_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.PROGRESS_PATH_NAME}/:teamMemberId`}
        >
          <TeamMemberProgressView />
        </SecureRoute>
        <SecureRoute
          path={`/${Constants.ROUTE_PATH_NAMES.TRAINING_PATH_NAME}/:id/${Constants.ROUTE_PATH_NAMES.TEAM_MEMBERS_PATH_NAME}`}
        >
          <ManagePlanView />
        </SecureRoute>
        <SecureRoute
          exact
          path={`/${Constants.ROUTE_PATH_NAMES.TRAINING_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.TRAINING_MODE_PATH_NAME}`}
        >
          <TrainingMode />
        </SecureRoute>
        <SecureRoute
          exact
          path={`/${Constants.ROUTE_PATH_NAMES.HEADLESS_PATH_NAME}/:documentId`}
        >
          <SignalDocument />
        </SecureRoute>
        <SecureRoute
          exact
          path={`/${Constants.ROUTE_PATH_NAMES.REPORTS_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.COMPLIANCE_PATH_NAME}`}
        >
          <ReportsCompliancePlansTab />
        </SecureRoute>
        <SecureRoute
          exact
          path={`/${Constants.ROUTE_PATH_NAMES.REPORTS_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.PLANS_PATH_NAME}`}
        >
          <ReportsPlansTab />
        </SecureRoute>
        <SecureRoute
          exact
          path={`/${Constants.ROUTE_PATH_NAMES.REPORTS_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.PLANS_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.PROGRESS_PATH_NAME}/:id/:compliancePlan?`}
        >
          <ReportsPlansView />
        </SecureRoute>
        <SecureRoute
          exact
          path={`/${Constants.ROUTE_PATH_NAMES.REPORTS_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.TEAM_MEMBERS_PATH_NAME}`}
        >
          <ReportsTeamMembersTab />
        </SecureRoute>
        <SecureRoute
          exact
          path={`/${Constants.ROUTE_PATH_NAMES.REPORTS_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.TEAM_MEMBERS_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.PROGRESS_PATH_NAME}/:id`}
        >
          <ReportsTeamMembersView />
        </SecureRoute>
        <SecureRoute
          exact
          path={`/${Constants.ROUTE_PATH_NAMES.DOCUMENT_PATH_NAME}/:documentId`}
        >
          {lazyComponent(() =>
            import(
              /* webpackChunkName: "route.doc/id" */ './containers/Document/Document'
            ),
          )}
        </SecureRoute>
        <SecureRoute
          exact
          path={`/${Constants.ROUTE_PATH_NAMES.LEADERSHIP_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.DOCUMENT_PATH_NAME}/:documentId/:isCompliance`}
        >
          {lazyComponent(() =>
            import(
              /* webpackChunkName: "route.doc/id" */ './containers/Document/Document'
            ),
          )}
        </SecureRoute>
        <SecureRoute
          exact
          path={`/${Constants.ROUTE_PATH_NAMES.COMPLIANCE_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.DOCUMENT_PATH_NAME}/:documentId/:isCompliance`}
        >
          {lazyComponent(() =>
            import(
              /* webpackChunkName: "route.doc/id" */ './containers/Document/Document'
            ),
          )}
        </SecureRoute>
        {featureflagAscendNav && (
          <SecureRoute
            exact
            path={`/${Constants.ROUTE_PATH_NAMES.LEADERSHIP_PATH_NAME}`}
          >
            {lazyComponent(() =>
              import(
                /* webpackChunkName: "route.leadership" */ './containers/Explore/Leadership/LeadershipTab'
              ),
            )}
          </SecureRoute>
        )}
        <SecureRoute
          exact
          path={`/${Constants.ROUTE_PATH_NAMES.ADMIN_PATH_NAME}`}
        >
          <Admin />
        </SecureRoute>
        <SecureRoute exact path="/">
          {lazyComponent(() =>
            import(
              /* webpackChunkName: "route.categories" */ './containers/Explore/Operations/OperationsTab'
            ),
          )}
        </SecureRoute>
      </NotFoundSwitch>
    </Suspense>
  );
};

const WhichRoutes = () => {
  const isNative = useIsNative();
  return isNative ? SecureRoutesHandledByNative : SecureRouteHandledByWeb;
};

export default WhichRoutes();
