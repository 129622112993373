import Constants from 'constants/index';
import { device } from 'util/device';
import { getNameFromLanguage } from 'util/language';
import { isApiError } from 'util/request';
import { generateTotalTime } from 'util/time';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  setHeader,
  setHeaderLabel,
  setHeaderSubtext,
} from 'store/header/slice';
import {
  selectShowProgressModal,
  selectTrainingModeData,
} from 'store/trainingMode/selectors';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import {
  exitTrainingMode,
  hideProgressModal,
  resetTrainingModeData,
} from 'store/trainingMode/slice';
import Bugsnag from '@bugsnag/browser';
import { formatBugsnagErrorMessage } from 'bugsnag';
import GenericError from 'sharedComponents/app/GenericError';
import {
  useGetAssignedStatusQuery,
  useUpdateChecklistStatusMutation,
  useUpdateChecklistStepStatusMutation,
} from 'services/pathwayApi';
import { selectAllLocationsWithAtLeastTrainer } from 'store/user/selectors';
import { selectSideBarReducerState } from 'store/sideBar/selectors';
import useTrainingPlanTimer from 'hooks/useTrainingPlanTimer';
import { Button } from 'cfa-react-components';
import ConfirmationModal from 'sharedComponents/app/popups/ConfirmationModal';
import PlanItemProcedure from './SharedComponents/PlansItemCards/PlanItemProcedure';
import PlanItemTask from './SharedComponents/PlansItemCards/PlanItemTask';
import PlanItemQuiz from './SharedComponents/PlansItemCards/PlanItemQuiz';
import PlanItemCard from './SharedComponents/PlansItemCards/PlanItemCard';
import PlanItemBrightcoveVideo from './SharedComponents/PlansItemCards/PlanItemBrightcoveVideo';

export const TrainingMode = () => {
  const dispatch = useDispatch();
  const {
    estimatedMinutes,
    languageSelected,
    planId,
    planLabel,
    planName,
    selectedTeamMembers,
    assignedTeamMembersStatus,
  } = useSelector(selectTrainingModeData);
  const { t } = useTranslation();
  const history = useHistory();
  const showProgressModal = useSelector(selectShowProgressModal);
  const sideBar = useSelector(selectSideBarReducerState);
  const [updateChecklistStepStatus] = useUpdateChecklistStepStatusMutation();
  const [updateChecklistStatus] = useUpdateChecklistStatusMutation();
  const [assignedPlan, setAssignedPlan] = useState([]);
  const [activeTask, setActiveTask] = useState('');
  const [activeQuizStepId, setActiveQuizStepId] = useState('');
  const [showPlanCompleteModal, setShowPlanCompleteModal] = useState(false);
  const locationsWithAtLeastTrainer = useSelector(
    selectAllLocationsWithAtLeastTrainer,
  );
  const assignedUserIds = selectedTeamMembers.map(member => member.adId);
  const {
    data: plan,
    error,
    refetch: refetchAssignedStatus,
  } = useGetAssignedStatusQuery(
    {
      checklist: planId,
      location: locationsWithAtLeastTrainer,
    },
    {
      skip: !planId,
    },
  );

  useEffect(() => {
    if (plan) {
      setAssignedPlan(plan);
      refetchAssignedStatus();
    }
  }, [plan, refetchAssignedStatus]);

  useEffect(() => {
    if (assignedPlan?.checklist?.name) {
      dispatch(
        setHeader(
          getNameFromLanguage(assignedPlan.checklist.name, languageSelected),
        ),
      );
    }
  }, [assignedPlan, dispatch, languageSelected, t]);

  useEffect(() => {
    // We route the user back to the Team Members page if they did not access this page through the Training Mode flow
    if (!planName) {
      history.push(`${Constants.ROUTE_PATH_NAMES.TEAM_PATH_NAME}`);
    } else if (planName) {
      dispatch(setHeader(planName));
      dispatch(setHeaderLabel(planLabel));
      dispatch(
        setHeaderSubtext(
          t('TrainingPlans.trainingMode.estimatedCompletionTime', {
            estimatedTime: generateTotalTime(
              estimatedMinutes,
              t('Generic.hour'),
              t('Generic.mins'),
            ),
          }),
        ),
      );
    }
  }, [dispatch, history, t, planName, planLabel, estimatedMinutes]);

  const onExit = () => {
    dispatch(setHeader(''));
    dispatch(setHeaderLabel(''));
    dispatch(setHeaderSubtext(''));
    dispatch(hideProgressModal());
    dispatch(exitTrainingMode());
    dispatch(resetTrainingModeData());

    switch (sideBar.linkClicked) {
      case Constants.SIDEBAR.EXPLORE:
        history.push({
          pathname: '/',
        });
        break;
      case Constants.SIDEBAR.SETTINGS:
        history.push({
          pathname: `/${Constants.ROUTE_PATH_NAMES.PROFILE_PATH_NAME}`,
        });
        break;
      case Constants.SIDEBAR.TRAINING:
        history.push({
          pathname: `/${Constants.ROUTE_PATH_NAMES.TRAINING_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.BUILD_PATH_NAME}`,
        });
        break;

      default:
        history.push({
          pathname: '/',
        });
    }
  };

  const isPlanComplete = assignedTeamMembersStatus.every(
    teamMember => teamMember.status === Constants.TRAINING_PLANS.COMPLETE,
  );

  const handleSetActiveTask = step => {
    activeTask === step.id ? setActiveTask('') : setActiveTask(step.id);
  };

  const handleSetActiveQuiz = step => {
    activeQuizStepId === step.id
      ? setActiveQuizStepId('')
      : setActiveQuizStepId(step.id);
  };

  const handleSetActiveVideo = step => {
    activeTask === step.id ? setActiveTask('') : setActiveTask(step.id);
  };

  const onCardClick = (step, id) => {
    history.push({
      pathname: `/${Constants.ROUTE_PATH_NAMES.TRAINING_PATH_NAME}/assigned/toComplete`,
      state: {
        planId: planId,
        step,
        id,
        isCompleted: isStepCompleted(step.id),
        userIds: assignedUserIds,
        stepStatus: getStepStatus(isStepCompleted(step.id)),
        isViewingFromTrainingPlan: true,
      },
    });
  };

  const onOvalIconToggle = id => {
    if (id === activeTask) {
      setActiveTask('');
    }
    updateChecklistStepStatus({
      checklistId: planId,
      stepId: id,
      status: isStepCompleted(id)
        ? Constants.TRAINING_PLANS.INCOMPLETE
        : Constants.TRAINING_PLANS.COMPLETE,
      userIds: assignedUserIds,
    })
      .unwrap()
      .then(() => {
        refetchAssignedStatus();
      })
      .catch(err => {
        Bugsnag.notify(formatBugsnagErrorMessage(err));
      });
  };

  const isStepCompleted = stepId => {
    return assignedPlan?.status
      ?.filter(status => assignedUserIds.find(id => status.userId === id))
      ?.every(
        planAssigned =>
          planAssigned?.steps?.find(step => step.stepId === stepId)?.status ===
          Constants.TRAINING_PLANS.COMPLETE,
      );
  };

  const getStepStatus = status => {
    return status
      ? Constants.TRAINING_PLANS.COMPLETE
      : Constants.TRAINING_PLANS.INCOMPLETE;
  };

  const [startTaskTimer, stopTaskTimer] = useTrainingPlanTimer(
    assignedPlan?.checklist?.id,
    activeTask,
    assignedUserIds,
    getStepStatus(isStepCompleted(activeTask)),
  );

  const [startQuizTimer, stopQuizTimer] = useTrainingPlanTimer(
    planId,
    activeQuizStepId,
    assignedUserIds,
    getStepStatus(isStepCompleted(activeQuizStepId)),
  );

  useEffect(() => {
    if (activeQuizStepId !== '' && isStepCompleted(activeQuizStepId)) {
      setActiveQuizStepId('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeQuizStepId, assignedPlan]);

  // timer for tasks
  useEffect(() => {
    if (activeTask !== '') {
      startTaskTimer();
    } else {
      stopTaskTimer();
    }
    return () => {
      stopTaskTimer();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTask]);

  // quiz timer
  useEffect(() => {
    if (activeQuizStepId !== '') {
      startQuizTimer();
    } else {
      stopQuizTimer();
    }
    return () => {
      stopQuizTimer();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeQuizStepId]);

  if (isApiError(error)) {
    Constants.BUGSNAG_ENABLED &&
      Bugsnag.notify(formatBugsnagErrorMessage(error));
    return <GenericError />;
  }

  const onCompleteButtonClick = () => {
    updateChecklistStatus({
      checklist: planId,
      status: Constants.TRAINING_PLANS.COMPLETED,
      userIds: assignedUserIds,
    })
      .unwrap()
      .then(() => {
        setShowPlanCompleteModal(true);
      })
      .catch(err => {
        Bugsnag.notify(formatBugsnagErrorMessage(err));
      });
  };

  const areAllStepsComplete = () => {
    const availableResourceCount =
      plan?.checklist.sections
        .flatMap(section => section?.steps?.filter(step => step.available))
        .concat(plan?.checklist?.steps.filter(step => step.available))
        ?.length ?? 0;

    return (
      assignedPlan?.checklist?.stepsTotal !== 0 &&
      assignedPlan?.status
        ?.filter(assignedUser =>
          assignedUserIds?.find(id => assignedUser?.userId === id),
        )
        .map(status => status.stepsComplete)
        .every(step => step === availableResourceCount)
    );
  };

  const goToExplorePage = () => {
    dispatch(setHeader(''));
    dispatch(setHeaderLabel(''));
    dispatch(setHeaderSubtext(''));
    dispatch(exitTrainingMode());
    dispatch(resetTrainingModeData());
    history.push({
      pathname: '/',
      state: {
        fromTrainingMode: true,
      },
    });
  };

  const renderCompleteButton = () => (
    <StyledButtonWrapper>
      <Button
        color="secondary"
        data-testid="CompleteButton"
        disabled={!areAllStepsComplete() || isPlanComplete}
        onClick={() => onCompleteButtonClick()}
      >
        {t('Button.complete')}
      </Button>
    </StyledButtonWrapper>
  );

  return (
    <StyledInnerPage>
      {assignedPlan?.checklist?.steps?.map((step, index) => {
        switch (step?.type) {
          case Constants.STEP_TYPES.QUIZ:
            return (
              <PlanItemCard key={index}>
                <PlanItemQuiz
                  isActive={activeQuizStepId === step.id}
                  isComplete={isStepCompleted(step.id)}
                  language={languageSelected}
                  onClick={() => handleSetActiveQuiz(step)}
                  onRefetch={refetchAssignedStatus}
                  planId={planId}
                  statuses={assignedPlan?.status}
                  step={step}
                  users={assignedUserIds}
                />
              </PlanItemCard>
            );
          case Constants.STEP_TYPES.TASK:
            return (
              <PlanItemCard key={index}>
                <PlanItemTask
                  isActive={activeTask === step.id}
                  isComplete={isStepCompleted(step.id)}
                  language={languageSelected}
                  onClick={() => handleSetActiveTask(step)}
                  onCompleteToggle={() => onOvalIconToggle(step.id)}
                  statuses={plan?.status}
                  step={step}
                />
              </PlanItemCard>
            );
          case Constants.STEP_TYPES.DOCUMENT:
            return step?.reference?.type?.toLowerCase() ===
              Constants.EXPANDED_FILE_FORMAT_TYPES.BRIGHTCOVE ? (
              <PlanItemCard key={index}>
                <PlanItemBrightcoveVideo
                  isActive={activeTask === step.id}
                  isComplete={isStepCompleted(step.id)}
                  language={languageSelected}
                  onClick={() => handleSetActiveVideo(step)}
                  onCompleteToggle={() => onOvalIconToggle(step.id)}
                  planId={plan?.checklist?.id}
                  statuses={plan?.status}
                  step={step}
                  users={[]}
                />
              </PlanItemCard>
            ) : (
              <PlanItemCard key={index}>
                <PlanItemProcedure
                  isActive={activeTask === step.id}
                  isComplete={isStepCompleted(step.id)}
                  isMissing={!step.available}
                  language={languageSelected}
                  onClick={() => onCardClick(step)}
                  onCompleteToggle={() => onOvalIconToggle(step.id)}
                  statuses={plan?.status}
                  step={step}
                />
              </PlanItemCard>
            );
          default:
            return null;
        }
      })}
      {assignedPlan?.checklist?.sections?.map(section => {
        return (
          !!section.steps.length && (
            <PlanItemCard
              disabled
              key={section?.id}
              sectionName={getNameFromLanguage(section.name, languageSelected)}
            >
              {section.steps.map((step, stepIndex) => {
                switch (step?.type) {
                  case Constants.STEP_TYPES.QUIZ:
                    return (
                      <PlanItemQuiz
                        isActive={activeQuizStepId === step.id}
                        isComplete={isStepCompleted(step.id)}
                        key={stepIndex}
                        language={languageSelected}
                        onClick={() => handleSetActiveQuiz(step)}
                        onRefetch={refetchAssignedStatus}
                        planId={planId}
                        statuses={assignedPlan?.status}
                        step={step}
                        users={assignedUserIds}
                      />
                    );
                  case Constants.STEP_TYPES.TASK:
                    return (
                      <PlanItemTask
                        isActive={activeTask === step.id}
                        isComplete={isStepCompleted(step.id)}
                        key={stepIndex}
                        language={languageSelected}
                        onClick={() => handleSetActiveTask(step)}
                        onCompleteToggle={() => onOvalIconToggle(step.id)}
                        statuses={plan?.status}
                        step={step}
                      />
                    );
                  case Constants.STEP_TYPES.DOCUMENT:
                    return step?.reference?.type?.toLowerCase() ===
                      Constants.EXPANDED_FILE_FORMAT_TYPES.BRIGHTCOVE ? (
                      <PlanItemBrightcoveVideo
                        isActive={activeTask === step.id}
                        isComplete={isStepCompleted(step.id)}
                        key={stepIndex}
                        language={languageSelected}
                        onClick={() => handleSetActiveVideo(step)}
                        onCompleteToggle={() => onOvalIconToggle(step.id)}
                        planId={plan?.checklist?.id}
                        statuses={plan?.status}
                        step={step}
                        users={[]}
                      />
                    ) : (
                      <PlanItemProcedure
                        isActive={activeTask === step.id}
                        isComplete={isStepCompleted(step.id)}
                        isMissing={!step.available}
                        key={stepIndex}
                        language={languageSelected}
                        onClick={() => onCardClick(step)}
                        onCompleteToggle={() => onOvalIconToggle(step.id)}
                        statuses={plan?.status}
                        step={step}
                      />
                    );
                  default:
                    return null;
                }
              })}
            </PlanItemCard>
          )
        );
      })}
      {assignedPlan && renderCompleteButton()}

      <ConfirmationModal
        bodyText={t('TrainingPlans.trainingMode.exitModal')}
        headerText={t('TrainingPlans.trainingMode.exitTrainingMode')}
        isOpen={showProgressModal}
        onClose={() => dispatch(hideProgressModal())}
        primaryButtonColor={'secondary'}
        primaryButtonHandler={onExit}
        primaryButtonText={t('Button.exit')}
        secondaryButtonHandler={() => dispatch(hideProgressModal())}
        secondaryButtonText={t('Button.stay')}
      />

      <ConfirmationModal
        bodyText={t('TrainingPlans.trainingMode.completedPlanModal', {
          planName: planName,
        })}
        headerText={t('Generic.planCompleted')}
        isOpen={showPlanCompleteModal}
        onClose={goToExplorePage}
        primaryButtonColor={'secondary'}
        primaryButtonHandler={goToExplorePage}
        primaryButtonText={t('Button.close')}
      />
    </StyledInnerPage>
  );
};

const StyledInnerPage = styled.div`
  width: 50%;
  margin: auto;
  margin-top: 3em;

  @media ${device.mobile} {
    width: 100%;
    padding: 0 1em;
  }
`;
const StyledButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 2em;
`;
