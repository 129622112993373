import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { FailedIconAlertTriangleFilled } from 'components/PlanCard/PlanCards/MyPlansComplianceCard';

const FailedAlertMessage = ({ children }) => {
  const { t } = useTranslation();

  return (
    <>
      <FailedIconAlertTriangleFilled />{' '}
      {t('TrainingPlans.compliancePlanNotPassed')}
      {children}
    </>
  );
};

FailedAlertMessage.propTypes = {
  children: PropTypes.node,
};

FailedAlertMessage.defaultProps = {
  children: null,
};

export default FailedAlertMessage;
