import Constants from 'constants/index';
import { getLaunchdDarklyUser } from 'util/user';
import * as LDClient from 'launchdarkly-js-client-sdk';
import { UserState } from 'store/user/slice';

export const getLdClient = async (user: UserState) => {
  const ldUser: {
    audience: any;
    email: any;
    key: any;
    kind: string;
    locations: any;
    name: any;
    userType: any;
  } = getLaunchdDarklyUser(user);

  const ldClient = LDClient.initialize(Constants.LAUNCH_DARKLY_CLIENT_ID, {
    kind: 'user',
    key: ldUser.key,
  });

  await ldClient.waitForInitialization();

  return ldClient;
};
