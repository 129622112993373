import Constants from 'constants/index';
import { getNameFromLanguage } from 'util/language';
import { getClickableLink } from 'util/url';
import { isValidUrl } from 'util/isValidUrl';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import isEmpty from 'lodash/isEmpty';
import {
  IconAlertTriangleFilled,
  IconCertificate,
  IconDeviceGamepad2,
  IconEdit,
  IconLink,
  IconNote,
  IconTrash,
} from '@tabler/icons-react';
import { IconButton, Typography } from 'cfa-react-components';
import PopoverMenuButton from 'components/PopoverMenuButton/PopoverMenuButton';
import PopoverMenuItemButton from 'components/PopoverMenuButton/PopoverMenuButtonItem';
import EditButtons from './EditButtons';
import EditTask from './EditTask';
import SixDotIcon from './SixDotIcon';

const TaskContainer = ({
  step,
  onDelete,
  isAllowedToEdit,
  sectionId,
  onCancel,
  onEdit,
  plan,
  className,
  taskUpdated,
  isPreview,
}) => {
  const { t } = useTranslation();
  const hasNotes = !isEmpty(step?.note);
  const hasUrls = !isEmpty(step?.urls[0]?.urlSet);
  const isTask = step?.type === Constants.STEP_TYPES.TASK;
  const isDocument = step?.type === Constants.STEP_TYPES.DOCUMENT;
  const isGame = step?.reference?.type === Constants.DOCUMENT_TYPES.GAME;
  const isQuiz = step?.type === Constants.STEP_TYPES.QUIZ;
  const isBrightCoveVideo =
    step?.type === Constants.STEP_TYPES.DOCUMENT &&
    step?.reference?.type?.toLowerCase() ===
      Constants.EXPANDED_FILE_FORMAT_TYPES.BRIGHTCOVE;
  const taskIsExpandable = !isDocument && (hasNotes || hasUrls);
  const name =
    isDocument && step?.available
      ? getNameFromLanguage(step?.reference?.name)
      : getNameFromLanguage(step?.name) ||
        getNameFromLanguage(step?.reference?.name);
  const [task, setTask] = useState({
    id: step?.id,
    name: name,
    note: getNameFromLanguage(step?.note) || getNameFromLanguage(step?.note),
    url: getNameFromLanguage(step?.urls?.[0]?.urlSet ?? ''),
  });
  const [showEditTask, setShowEditTask] = useState(false);
  const [showExpandedItem, setShowExpandedItem] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    if (taskUpdated) {
      setError('');
    }
  }, [taskUpdated]);

  const handleCancel = () => {
    setError('');
    onCancel();
    setShowEditTask(false);
    setTask({
      id: '',
      name:
        getNameFromLanguage(step?.name) ||
        getNameFromLanguage(step?.reference?.name),
      note: getNameFromLanguage(step?.note) || getNameFromLanguage(step?.note),
      url: getNameFromLanguage(step?.urls?.[0]?.urlSet ?? ''),
    });
  };

  const handleOnEdit = (type, updatedSectionTitle, editableTask, id) => {
    const editAndClearUrl = () => {
      onEdit(type, updatedSectionTitle, { ...editableTask, url: '' }, id);
      setTask({ ...task, url: '' });
    };

    const editAndTrimUrl = () => {
      onEdit(
        type,
        updatedSectionTitle,
        { ...editableTask, url: editableTask.url.trim() },
        id,
      );
      setTask({ ...task, url: task.url.trim() });
    };
    if (
      !editableTask.url ||
      isValidUrl(editableTask.url.trim()) ||
      editableTask?.url?.trim() === ''
    ) {
      editableTask.url === undefined || editableTask.url.trim() === ''
        ? editAndClearUrl()
        : editAndTrimUrl();
      setShowEditTask(false);
      setError('');
    } else {
      setError(t('Input.Errors.url'));
    }
  };

  const handleItemClick = () => {
    if (isTask && taskIsExpandable && !showEditTask) {
      setShowExpandedItem(prev => !prev);
    }
  };

  const taskTitle = isBrightCoveVideo
    ? t('Generic.video')
    : isDocument && isGame
    ? t('Generic.game')
    : isDocument
    ? t('Generic.resource')
    : isQuiz
    ? t('Generic.quiz')
    : t('Generic.task');

  return (
    <StyledTaskContainer className={className}>
      <TaskAndProcedure>
        {!isPreview && (
          <SixDotIcon position={showEditTask ? 'top' : 'center'} />
        )}
        <TaskOrProcedureBodyAndIconsWrapper>
          {showEditTask && (
            <InlineTextEditContainer>
              <EditTask
                error={error}
                onEdit={() => {
                  handleOnEdit(
                    Constants.TRAINING_MENU_OPTIONS.TASK,
                    null,
                    task,
                    sectionId,
                  );
                }}
                plan={plan}
                setTask={setTask}
                task={task}
              />
              <EditButtonsWrapper>
                <EditButtons
                  onCancel={handleCancel}
                  onEdit={() =>
                    handleOnEdit(
                      Constants.TRAINING_MENU_OPTIONS.TASK,
                      null,
                      task,
                      sectionId,
                    )
                  }
                  validated={task.name.trim().length > 0}
                />
              </EditButtonsWrapper>
            </InlineTextEditContainer>
          )}
          {!showEditTask && (
            <>
              <TaskAndProcedureBody
                $isExpandable={taskIsExpandable}
                onClick={handleItemClick}
              >
                <StyledTaskType data-testid="TaskTitle" variant="overline3">
                  {taskTitle}
                </StyledTaskType>
                <StyledTaskName data-testid="TaskName">
                  {isDocument && !step?.available ? (
                    <>
                      <StyledTaskNameText variant="body1">
                        {/* remove underscores from name */}
                        {name.replace(/_/g, ' ')}
                      </StyledTaskNameText>
                      <StyledWarningWrapper>
                        <IconAlertTriangleFilled size={16} />
                        <StyledWarningMessage variant="body1">
                          {t('TrainingPlans.noResourceBuild')}
                        </StyledWarningMessage>
                      </StyledWarningWrapper>
                    </>
                  ) : (
                    <StyledTaskNameText variant="body1">
                      {name}
                    </StyledTaskNameText>
                  )}
                </StyledTaskName>
              </TaskAndProcedureBody>
              <TaskAndProcedureIconsWrapper $isQuiz={isQuiz}>
                {isGame && (
                  <TaskAndProcedureIcon $isGame={isGame}>
                    <PlanItemTypeIcon>
                      <IconDeviceGamepad2 />
                    </PlanItemTypeIcon>
                  </TaskAndProcedureIcon>
                )}
                {isQuiz && (
                  <TaskAndProcedureIcon $isQuiz={isQuiz}>
                    <PlanItemTypeIcon>
                      <IconCertificate />
                    </PlanItemTypeIcon>
                  </TaskAndProcedureIcon>
                )}
                {hasNotes && (
                  <TaskAndProcedureIcon
                    data-testid="TaskNoteIcon"
                    onClick={() => setShowExpandedItem(prev => !prev)}
                  >
                    <PlanItemTypeIcon>
                      <IconNote />
                    </PlanItemTypeIcon>
                  </TaskAndProcedureIcon>
                )}
                {hasUrls && (
                  <TaskAndProcedureIcon
                    data-testid="TaskUrlIcon"
                    onClick={() => setShowExpandedItem(prev => !prev)}
                  >
                    <PlanItemTypeIcon>
                      <IconLink />
                    </PlanItemTypeIcon>
                  </TaskAndProcedureIcon>
                )}
                {!isPreview && isAllowedToEdit() && isTask && (
                  <PopoverMenuButton data-testid="ThreeDotMenu">
                    <PopoverMenuItemButton
                      data-testid="TaskEdit"
                      icon={<IconEdit />}
                      onClick={() => {
                        setShowExpandedItem(false);
                        setShowEditTask(true);
                      }}
                      text={t('TrainingPlans.manageThreeDotMenu.editTask')}
                    />
                    <PopoverMenuItemButton
                      data-testid="ThreeDotMenuDeletePlanPopup"
                      icon={<IconTrash data-testid="DeleteTask" />}
                      isDestructive={true}
                      onClick={() =>
                        onDelete({
                          id: step.id,
                          name:
                            getNameFromLanguage(step?.name) ||
                            getNameFromLanguage(step?.reference?.name),
                          sectionId,
                          type: step.type,
                        })
                      }
                      text={t('TrainingPlans.manageThreeDotMenu.deleteTask')}
                    />
                  </PopoverMenuButton>
                )}
                {!isTask && isAllowedToEdit() && (
                  <IconButton>
                    <IconTrash
                      data-testid="ResourceTrashIcon"
                      onClick={() =>
                        onDelete({
                          id: step.id,
                          name:
                            getNameFromLanguage(step?.name) ||
                            getNameFromLanguage(step?.reference?.name),
                          sectionId,
                          type: step.type,
                        })
                      }
                    />
                  </IconButton>
                )}
              </TaskAndProcedureIconsWrapper>
            </>
          )}
        </TaskOrProcedureBodyAndIconsWrapper>
      </TaskAndProcedure>
      {showExpandedItem && isTask && (
        <TaskAndProcedureNotesLinkWrapper>
          {hasNotes && (
            <TaskAndProcedureNotes>
              <Typography variant="overline3">{t('Generic.note')}</Typography>
              <Typography data-testid="TaskNote" variant="body1">
                {getNameFromLanguage(step.note)}
              </Typography>
            </TaskAndProcedureNotes>
          )}
          {hasUrls &&
            step.urls.map((link, idx) => (
              <TaskAndProcedureLink
                href={getClickableLink(getNameFromLanguage(link.urlSet))}
                key={`${link.id}-${idx}`}
                target="_blank"
              >
                <Typography variant="overline3">{t('Generic.link')}</Typography>
                <TaskAndProcedureLinkDescription
                  data-testid="TaskUrl"
                  fontWeight="extralight"
                  variant="body1"
                >
                  {getNameFromLanguage(link.urlSet)}
                </TaskAndProcedureLinkDescription>
              </TaskAndProcedureLink>
            ))}
        </TaskAndProcedureNotesLinkWrapper>
      )}
    </StyledTaskContainer>
  );
};

TaskContainer.propTypes = {
  step: PropTypes.object.isRequired,
  onDelete: PropTypes.func.isRequired,
  isAllowedToEdit: PropTypes.func.isRequired,
  sectionId: PropTypes.string,
  onCancel: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  plan: PropTypes.object.isRequired,
  className: PropTypes.string,
  taskUpdated: PropTypes.bool.isRequired,
  isPreview: PropTypes.bool,
};

TaskContainer.defaultProps = {
  sectionId: '',
  className: '',
  isPreview: false,
};

const InlineTextEditContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
`;

const PlanItemTypeIcon = styled.div`
  color: ${({ theme }) => theme.grayScale.gray2};
`;

const StyledTaskContainer = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.primaryPalette.white};
`;

const TaskAndProcedure = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 16px;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.primaryPalette.white};
`;

const StyledTaskName = styled.div`
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
`;

const StyledWarningWrapper = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.semanticColors.error};
`;

const StyledWarningMessage = styled(Typography)`
  color: ${({ theme }) => theme.semanticColors.error};
  margin-left: 0.5em;
`;

const StyledTaskNameText = styled(Typography)`
  display: flex;
  align-items: center;
`;

const TaskAndProcedureIcon = styled.div`
  width: 25px;
  margin: 0 5px;
  cursor: ${({ $isGame, $isQuiz }) =>
    !$isGame && !$isQuiz ? 'pointer' : 'default'};
`;

const TaskAndProcedureBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  cursor: ${({ $isExpandable }) => ($isExpandable ? 'pointer' : 'default')};
`;

const StyledTaskType = styled(Typography)`
  color: ${({ theme }) => theme.grayScale.gray6};
`;

const TaskOrProcedureBodyAndIconsWrapper = styled.div`
  display: contents;
`;

const TaskAndProcedureIconsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  cursor: ${({ $isQuiz }) => (!$isQuiz ? 'pointer' : 'default')};
`;

const TaskAndProcedureNotes = styled.div`
  padding-top: 10px;
`;
const TaskAndProcedureLink = styled.a`
  padding-top: 10px;
`;

const TaskAndProcedureLinkDescription = styled(Typography)`
  color: ${({ theme }) => theme.primaryPalette.navyBlue};
  text-decoration: underline;
  word-wrap: break-word;

  &:hover {
    color: ${({ theme }) => theme.primaryPalette.button.hover};
    font-weight: normal;
    text-decoration: underline;
    cursor: pointer;
  }
`;

const TaskAndProcedureNotesLinkWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-left: 36px;

  a:hover {
    font-weight: normal;
  }
`;

const EditButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export default TaskContainer;
