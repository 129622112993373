import { createSlice } from '@reduxjs/toolkit';

export interface reduxScrollPosition {
  id: string;
  data: any[];
  pageNumber: number;
  yPostition: number;
  query: string;
}

export const slice = createSlice({
  name: 'scrollPosition',
  initialState: {
    id: '',
    data: [],
    pageNumber: 1,
    yPostition: 0,
    query: '',
  },
  reducers: {
    setData: (state, action) => {
      state.data = action.payload;
    },
    setId: (state, action) => {
      state.id = action.payload;
    },
    setPageNumber: (state, action) => {
      state.pageNumber = action.payload;
    },
    setQuery: (state, action) => {
      state.query = action.payload;
    },
    setYPosition: (state, action) => {
      state.yPostition = action.payload;
    },
    resetState: state => {
      state.data = [];
      state.id = '';
      state.pageNumber = 1;
      state.yPostition = 0;
    },
  },
});
export const {
  setData,
  setId,
  setPageNumber,
  setQuery,
  setYPosition,
  resetState,
} = slice.actions;
export default slice.reducer;
