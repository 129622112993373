import { getRefreshTokenWithRedirect, isUnAuthorizedError } from 'util/auth';
import { BaseQueryFn, createApi } from '@reduxjs/toolkit/query/react';
import {
  ApiError,
  OpenAPI,
  OpenAPIConfig,
  OperatorDTO,
  ComplianceConfigurationDTO,
  CourseEntity,
  BrowseResponse,
  PathwayXPClient,
  SearchResponse,
  UserPreferencesEntity,
  UserChecklistResponse,
  UserChecklistStatusResponse,
  CourseReportDTO,
  Course,
  AssignableCourseResult,
  AssignableResponse,
  PathwayUser,
  UserChecklistResultDTO,
} from '@cfacorp-pathway/xp-api-typescript-client';
import store from 'store';
import Constants from '../constants/index';

export const getApiToken = () => {
  const localStorageToken = localStorage.getItem('okta-token-storage');
  const webToken = localStorageToken
    ? JSON.parse(localStorageToken)?.accessToken?.accessToken
    : null;
  const iOSToken = (window as any)?.accessToken;
  const reactNativeWebView = (window as any)?.ReactNativeWebView;
  let androidToken;
  if (
    reactNativeWebView &&
    typeof reactNativeWebView.injectedObjectJson === 'function'
  ) {
    androidToken = JSON.parse(
      (reactNativeWebView as any)?.injectedObjectJson(),
    )?.accessToken;
  }
  return webToken || iOSToken || androidToken;
};

export const xpApiConfig: OpenAPIConfig = {
  ...OpenAPI,
  BASE: Constants.XP_API_BASE_URL,
  HEADERS: {
    'Content-Type': 'application/json',
  },
  TOKEN: () => getApiToken(),
  CREDENTIALS: 'include',
  WITH_CREDENTIALS: true,
};

const pathwayXPApiBaseQuery =
  (): BaseQueryFn<
    {
      sdkFunction: Function;
      data?: object;
    },
    unknown,
    unknown
  > =>
  async ({ sdkFunction, data }) => {
    try {
      const result = await sdkFunction(data);
      return { data: result };
    } catch (error) {
      const err: ApiError = error;
      // if unauthorized, get refresh token and redirect
      if (isUnAuthorizedError(err)) {
        // invalidate Browse api response cache when user refreshes token
        store.dispatch(xpApi.util.invalidateTags(['Browse']));
        await getRefreshTokenWithRedirect();
      }
      return {
        error: {
          status: err?.status,
          data: err?.body || err.message,
        },
      };
    }
  };

const client = new PathwayXPClient(xpApiConfig);

export const xpApi = createApi({
  reducerPath: 'xpApi',
  baseQuery: pathwayXPApiBaseQuery(),
  tagTypes: ['Browse'],
  endpoints: builder => ({
    getBrowseStructure: builder.query<
      BrowseResponse,
      { country: string; language: string }
    >({
      query: ({ country, language }) => ({
        sdkFunction: async args => await client.browse.browseStructure(args),
        data: { country, language },
      }),
      providesTags: ['Browse'],
      keepUnusedDataFor: Number.MAX_SAFE_INTEGER,
    }),
    getAllCourses: builder.query<
      Array<CourseEntity>,
      { isAdmin?: boolean; type: string }
    >({
      query: ({ isAdmin, type }) => ({
        sdkFunction: async args => await client.courses.getAllCourses(args),
        data: { isAdmin, type },
      }),
    }),
    setOperatorCourseSetting: builder.mutation<
      any,
      {
        courseId: string;
        enable: string;
        operatorId: string;
      }
    >({
      query: ({ courseId, enable, operatorId }) => ({
        sdkFunction: async args =>
          await client.courses.setOperatorCourseSetting(args),
        data: { courseId, enable, operatorId },
      }),
    }),
    addSearchResultFeedback: builder.mutation<
      {
        resultId: string;
        queryId: string;
      },
      {
        resultId: string;
        queryId: string;
      }
    >({
      query: ({ resultId, queryId }) => ({
        sdkFunction: async args =>
          await client.search.searchClickFeedback(args),
        data: { resultId, queryId },
      }),
    }),
    getSearchResults: builder.query<
      SearchResponse,
      {
        query: string;
        country?: string;
        language?: string;
        pageNumber?: number;
      }
    >({
      query: ({ query, country, language, pageNumber }) => ({
        sdkFunction: async args => await client.search.search(args),
        data: { query, country, language, pageNumber },
      }),
    }),
    saveUserPreferences: builder.mutation<
      UserPreferencesEntity,
      {
        country?: string;
        language?: string;
        location?: string;
        userId?: string;
      }
    >({
      query: ({ country, language, location, userId }) => ({
        sdkFunction: async args => await client.users.saveUserPreferences(args),
        data: { requestBody: { country, language, location, userId } },
      }),
    }),
    getReportsUserTrainingPlans: builder.query<
      UserChecklistResponse,
      { userId: string }
    >({
      query: ({ userId }) => ({
        sdkFunction: async args =>
          await client.reports.getUserTrainingPlans(args),
        data: { adId: userId },
      }),
    }),
    getReportsTrainingPlanStatuses: builder.query<
      UserChecklistStatusResponse,
      { locations: string[] }
    >({
      query: ({ locations }) => ({
        sdkFunction: async args => await client.reports.listPlanStatus(args),
        data: { locationIds: locations },
      }),
    }),
    getCourseReport: builder.query<
      CourseReportDTO,
      { courseId: string; locations: string[] }
    >({
      query: ({ courseId, locations }) => ({
        sdkFunction: async args =>
          await client.courses.generateCourseReport(args),
        data: { courseId, location: locations },
      }),
    }),
    getOperatorCourseSettings: builder.query<
      ComplianceConfigurationDTO,
      { operatorId: string }
    >({
      query: ({ operatorId }) => ({
        sdkFunction: async args =>
          await client.courses.getOperatorCourseSettings(args),
        data: { operatorId },
      }),
    }),
    getComplianceCourses: builder.query<Course[], void>({
      query: () => ({
        sdkFunction: async () => await client.courses.getComplianceCourses(),
      }),
    }),
    getOperators: builder.query<OperatorDTO, void>({
      query: () => ({
        sdkFunction: async () => await client.users.getOperators(),
      }),
    }),
    getEnrollmentsByCourse: builder.query<
      AssignableCourseResult,
      { courseId: string; locations: string[] }
    >({
      query: ({ courseId, locations }) => ({
        sdkFunction: async args =>
          await client.courses.listEnrollmentsByCourse(args),
        data: { courseId, listOfLocationsToFilter: locations },
      }),
    }),
    getUsersForLocations: builder.query<PathwayUser[], { locations: string[] }>(
      {
        query: ({ locations }) => ({
          sdkFunction: async args =>
            await client.users.getUsersForLocations(args),
          data: { locationNumbers: locations },
        }),
      },
    ),
    getAssignableChecklistsAndCourses: builder.query<
      AssignableResponse,
      { locations?: string[] }
    >({
      query: ({ locations }) => ({
        sdkFunction: async args =>
          await client.checklistV2.listAssignableChecklists(args),
        data: { locationIds: locations },
      }),
    }),
    getStatusesAssignedToChecklist: builder.query<
      UserChecklistResultDTO,
      { checklistId: string; locations: string[] }
    >({
      query: ({ checklistId, locations }) => ({
        sdkFunction: async args =>
          await client.checklistV2.listAssignedByChecklist(args),
        data: { checklistId, locationIds: locations },
      }),
    }),
  }),
});

export const {
  useAddSearchResultFeedbackMutation,
  useGetBrowseStructureQuery,
  useGetOperatorCourseSettingsQuery,
  useGetSearchResultsQuery,
  useGetAllCoursesQuery,
  useSetOperatorCourseSettingMutation,
  useSaveUserPreferencesMutation,
  useGetReportsUserTrainingPlansQuery,
  useGetReportsTrainingPlanStatusesQuery,
  useGetCourseReportQuery,
  useGetComplianceCoursesQuery,
  useGetEnrollmentsByCourseQuery,
  useGetUsersForLocationsQuery,
  useGetAssignableChecklistsAndCoursesQuery,
  useGetOperatorsQuery,
  useGetStatusesAssignedToChecklistQuery,
} = xpApi;
