import { useParams } from 'react-router-dom';
import { ChickenSpinner } from 'sharedComponents/universal';
import { useEffect } from 'react';
import styled from 'styled-components';
import { selectUser } from 'store/user/selectors';
import { useSelector } from 'react-redux';
import { useAmplitudeTrack } from 'amplitude/useAmplitude';
import useDocumentCookieRefresh from 'hooks/useDocumentCookieRefresh';
import Constants from '../../constants/index';
import PdfDownload from './PdfDownload';

const PrintView = () => {
  const params = useParams();
  const { documentId } = params;
  const track = useAmplitudeTrack();
  const user = useSelector(selectUser);
  const { isSuccess: isDocumentCookieSuccess, error } =
    useDocumentCookieRefresh();

  if (error) {
    console.error(error);
  }

  useEffect(() => {
    if (track !== null) {
      track('document_print', {
        document_id: documentId ?? 'empty',
        datetime: Date().toLocaleString() ?? 'empty',
      });
    }
  }, [track, documentId, user.locations]);

  return (
    <>
      <StyledFullScreen>
        {isDocumentCookieSuccess ? (
          <PdfDownload
            url={`${Constants.PATHWAY_API_URL}/${Constants.PATHWAY_API.BINARY_PATH_NAME}/${documentId}`}
          ></PdfDownload>
        ) : (
          <StyledChickenSpinnerContainer>
            <ChickenSpinner />
          </StyledChickenSpinnerContainer>
        )}
      </StyledFullScreen>
    </>
  );
};

const StyledFullScreen = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background: white;
  z-index: 2;
  min-height: 100vh;
  width: 100vw;
  background: ${props => props.theme.surfaceColors.gray};
`;

const StyledChickenSpinnerContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background: white;

  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default PrintView;
