import { getNameFromLanguage } from 'util/language';
import { mapCategoryToAliasTranslation } from 'util/categoryUtils';
import Constants from 'constants/index';
import PropTypes from 'prop-types';
import PlanCard from 'components/PlanCard/PlanCard';
import { useTranslation } from 'react-i18next';
import PlanCardContent from 'components/PlanCard/PlanCardContent/PlanCardContent';
import { IconAlertTriangleFilled, IconPrinter } from '@tabler/icons-react';
import styled from 'styled-components';
import { Tooltip } from 'cfa-react-components';
import {
  useGetAssignedCoursesQuery,
  useGetTeamMembersQuery,
} from 'services/pathwayApi';
import { useGetUsersForLocationsQuery } from 'services/xpApi';
import { selectAllLocationsWithAtLeastTrainer } from 'store/user/selectors';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useFlags } from 'launchdarkly-react-client-sdk';
import PlanCardSubHeader from '../PlanCardComponents/PlanCardSubHeader';
import PlanCardLabel from '../PlanCardComponents/PlanCardLabel';
import PlanCardTitle from '../PlanCardComponents/PlanCardTitle';

const ReportsCompliancePlanCard = ({
  course,
  className,
  'data-testid': dataTestId,
  enrollments,
  locations,
  onPrintReport,
}) => {
  const { t } = useTranslation();
  const { xpApi: xpApiFeatureFlag } = useFlags();
  const locationsWithAtLeastTrainer = useSelector(
    selectAllLocationsWithAtLeastTrainer,
  );
  const [totalTeamMembers, setTotalTeamMembers] = useState();

  const { data: oldAllTeamMembersData } = useGetTeamMembersQuery(
    {
      locations: locationsWithAtLeastTrainer,
    },
    { skip: !!xpApiFeatureFlag, refetchOnMountOrArgChange: true },
  );

  const { data: xpAllTeamMembersData } = useGetUsersForLocationsQuery(
    { locations: locationsWithAtLeastTrainer },
    { skip: !xpApiFeatureFlag },
  );

  const { data: complianceStatus, isSuccess: isAssignedCoursesSuccess } =
    useGetAssignedCoursesQuery({
      courseId: course.id,
      location: locationsWithAtLeastTrainer,
    });

  const data = xpApiFeatureFlag ? xpAllTeamMembersData : oldAllTeamMembersData;

  useEffect(() => {
    if (
      isAssignedCoursesSuccess &&
      complianceStatus?.enrollments !== undefined
    ) {
      setTotalTeamMembers(
        data
          ?.map(teamMember => {
            return {
              userId:
                complianceStatus?.enrollments?.[teamMember?.adId]?.userId ??
                teamMember.adId,
              hidden:
                complianceStatus?.enrollments?.[teamMember?.adId]?.hidden ??
                false,
            };
          })
          .filter(
            (value, index, self) =>
              index ===
              self.findIndex(
                user => user.userId === value.userId && !user.hidden,
              ),
          ).length,
      );
    }
  }, [complianceStatus, data, isAssignedCoursesSuccess]);

  const completedUsers = Object.values(enrollments)?.filter(
    enrollment =>
      enrollment.status === Constants.LEARN_UPON_TRAINING_PLANS.COMPLETED ||
      enrollment.status === Constants.LEARN_UPON_TRAINING_PLANS.PASSED,
  ).length;
  const allUsersCompleted =
    !!totalTeamMembers && completedUsers === totalTeamMembers;
  const percentComplete = (completedUsers / totalTeamMembers) * 100;
  return (
    <>
      {!!course && (
        <>
          <PlanCard
            buttons={
              <StyledPrinterIcon
                onClick={e => {
                  e.preventDefault();
                  onPrintReport({
                    category: Constants.PLAN_CATEGORIES.COMPLIANCE,
                    id: course?.id,
                    locations: locations,
                  });
                }}
              />
            }
            category={Constants.PLAN_CATEGORIES.COMPLIANCE}
            className={className}
            data-testid={dataTestId}
            disabled={!course?.enabled}
            link={`/${Constants.ROUTE_PATH_NAMES.REPORTS_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.PLANS_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.PROGRESS_PATH_NAME}/${course?.id}?compliancePlan="true"`}
            progress={allUsersCompleted ? null : percentComplete}
          >
            <PlanCardContent>
              <PlanCardLabel>
                {t(
                  mapCategoryToAliasTranslation(
                    Constants.PLAN_CATEGORIES.COMPLIANCE,
                  ),
                )}
              </PlanCardLabel>
              <PlanCardTitle>
                {getNameFromLanguage(course?.courseName)}
              </PlanCardTitle>
              {allUsersCompleted ? (
                <PlanCardSubHeader
                  className="success"
                  data-testid="PlanTaskCount"
                >
                  {t('Generic.completedByAll')}
                </PlanCardSubHeader>
              ) : (
                <PlanCardSubHeader data-testid="PlanTaskCount">
                  {totalTeamMembers ? (
                    <>
                      <Tooltip
                        content={t(
                          'TrainingPlans.accountability.outOfCompliance',
                        )}
                        placement="bottom"
                      >
                        <WarningIconAlertTriangleFilled
                          style={{ marginRight: '4px' }}
                        />
                      </Tooltip>
                      {`${completedUsers}/${totalTeamMembers} ${t(
                        'Generic.teamMembersCompleted',
                      )}`}
                    </>
                  ) : (
                    ` ${t('Generic.loading')}`
                  )}
                </PlanCardSubHeader>
              )}
            </PlanCardContent>
          </PlanCard>
        </>
      )}
    </>
  );
};

ReportsCompliancePlanCard.propTypes = {
  className: PropTypes.string,
  'data-testid': PropTypes.string,
  course: PropTypes.shape({
    courseID: PropTypes.string,
    courseName: PropTypes.object,
    createdDate: PropTypes.string,
    enabled: PropTypes.bool,
    id: PropTypes.string,
    locations: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  enrollments: PropTypes.objectOf(
    PropTypes.shape({
      completedDate: PropTypes.string,
      courseId: PropTypes.string,
      dueDate: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      duration: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      enrolledDate: PropTypes.string,
      enrollmentId: PropTypes.string,
      finalScore: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      hidden: PropTypes.bool,
      mostRecentCompletedDate: PropTypes.string,
      pathwayCourseId: PropTypes.string,
      percentComplete: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
      startedDate: PropTypes.string,
      status: PropTypes.string,
    }),
  ).isRequired,
  locations: PropTypes.arrayOf(PropTypes.string),
  onPrintReport: PropTypes.func.isRequired,
};

ReportsCompliancePlanCard.defaultProps = {
  className: '',
  'data-testid': 'ManagePlansPlanCard',
  locations: [],
};

const StyledPrinterIcon = styled(IconPrinter)`
  pointer-events: auto;
  color: ${({ theme }) => theme.grayScale.gray2};
`;

const WarningIconAlertTriangleFilled = styled(IconAlertTriangleFilled)`
  height: 16px;
  width: 16px;
  margin-right: 4px;
  color: ${({ theme }) => theme.semanticColors.warning};
`;

export default ReportsCompliancePlanCard;
