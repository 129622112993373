import { createSlice } from '@reduxjs/toolkit';

export const slice = createSlice({
  name: 'document',
  initialState: {
    category: '',
    isPrinting: false,
    isTranslating: false,
    isTridion: false,
    name: '',
    subcategory: '',
  },
  reducers: {
    setCategoryName: (state, action) => {
      state.category = action.payload.category;
    },
    setDocumentName: (state, action) => {
      state.name = action.payload.name;
    },
    setDocumentUrl: (state, action) => {
      state.url = action.payload.url;
    },
    setIsPrinting: (state, action) => {
      state.isPrinting = action.payload.isPrinting;
    },
    setSubcategoryName: (state, action) => {
      state.subcategory = action.payload.subcategory;
    },
    setIsTranslating: (state, action) => {
      state.isTranslating = action.payload.isTranslating;
    },
    setIsTridion: (state, action) => {
      state.isTridion = action.payload.isTridion;
    },
  },
});

export const {
  setCategoryName,
  setDocumentName,
  setDocumentUrl,
  setIsPrinting,
  setSubcategoryName,
  setIsTranslating,
  setIsTridion,
} = slice.actions;

export default slice.reducer;
