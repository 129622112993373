import Constants from 'constants/index';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Ribbon } from 'icons';
import { useTranslation } from 'react-i18next';
import { Tag, Tooltip, Typography } from 'cfa-react-components';

const ProgressBar = ({
  completionDate,
  current,
  isComplete,
  isDisabled,
  isMyPlan,
  isReports,
  selectedDueDate,
  showDate,
  startDate,
  text,
  total,
}) => {
  const { t } = useTranslation();
  const timeDifference = selectedDueDate - new Date().getTime();
  const dayDifference = timeDifference / (1000 * 3600 * 24);
  const weekDifference = dayDifference / 7;

  const returnTimeLeft = () =>
    Math.ceil(dayDifference) === 7
      ? t('TrainingPlans.accountability.due.oneWeek')
      : Math.ceil(dayDifference) < 0
      ? t('TrainingPlans.accountability.due.overdue')
      : Math.ceil(dayDifference) === 1
      ? t('TrainingPlans.accountability.due.oneDay')
      : Math.ceil(dayDifference) < 7
      ? t('TrainingPlans.accountability.due.xDays', {
          daysLeft: Math.ceil(dayDifference),
        })
      : Math.ceil(dayDifference) >= 8 && Math.floor(weekDifference) === 1
      ? t('TrainingPlans.accountability.due.oneWeek')
      : t('TrainingPlans.accountability.due.xWeeks', {
          weeksLeft: Math.floor(weekDifference),
        });

  const returnColor = () =>
    Math.ceil(dayDifference) >= 7
      ? Constants.DUEDATE_COLORS.DARK_GREEN
      : Math.ceil(dayDifference) < 7 && Math.ceil(dayDifference) >= 0
      ? Constants.DUEDATE_COLORS.TAN
      : Constants.DUEDATE_COLORS.PURPLE;

  return (
    <>
      <StyledProgressWrapper>
        {isReports ? (
          <StyledProgressBarTextWrapper>
            <StyledProgressBarText $isDisabled={isDisabled} variant="body2">
              {text}
            </StyledProgressBarText>
            {!!startDate && (
              <StyledProgressBarText $isDisabled={isDisabled} variant="body2">
                {`${t('Reports.filtering.startDate')}: ${new Date(
                  startDate,
                ).toLocaleDateString()}`}
              </StyledProgressBarText>
            )}
          </StyledProgressBarTextWrapper>
        ) : (
          <StyledProgressBarText
            $isDisabled={isDisabled}
            data-testid="ProgressBarText"
            variant="body2"
          >
            {text}
          </StyledProgressBarText>
        )}
        {isComplete ? (
          <Tooltip
            content={
              <>
                <strong>{t('TrainingPlans.statusOptions.completed')}:</strong>{' '}
                {new Date(completionDate).toLocaleDateString()}
              </>
            }
            placement="top"
            showOnElementEvents={['hover']}
          >
            <StyledRibbon $isReports={isReports} />
          </Tooltip>
        ) : showDate && selectedDueDate && isMyPlan ? (
          <Tooltip
            content={
              !isComplete &&
              t('TrainingPlans.accountability.due.dueDate', {
                dueDate: new Date(selectedDueDate).toLocaleDateString(),
              })
            }
            placement="top"
            showOnElementEvents={['hover']}
          >
            <StyledTagWrapper>
              <Tag
                color={returnColor()}
                data-testid="DateTag"
                label={returnTimeLeft()}
                variant="filled"
              />
            </StyledTagWrapper>
          </Tooltip>
        ) : showDate && selectedDueDate && !isNaN(selectedDueDate) ? (
          <Tooltip
            content={
              !isComplete &&
              t('TrainingPlans.accountability.due.dueDate', {
                dueDate: selectedDueDate
                  ? new Date(selectedDueDate).toLocaleDateString()
                  : t('TrainingPlans.accountability.due.noDueDate'),
              })
            }
            placement="top"
            showOnElementEvents={['hover']}
          >
            <StyledTagWrapper>
              <Tag
                color={returnColor()}
                data-testid="DateTag"
                label={returnTimeLeft()}
                variant="filled"
              />
            </StyledTagWrapper>
          </Tooltip>
        ) : null}
      </StyledProgressWrapper>

      {!isComplete && (
        <StyledProgressBarWrapper>
          <StyledProgressBar
            $isDisabled={isDisabled}
            progress={{
              current,
              total,
            }}
          />
        </StyledProgressBarWrapper>
      )}
    </>
  );
};

const getProgressBarWidth = (current, total) => {
  if (current === 0 && total === 0) {
    return 0;
  }
  const percent = ((current / total) * 100).toFixed(2);
  return percent > 100 ? 100 : percent;
};

const StyledProgressBarWrapper = styled.div`
  background: ${props => props.theme.grayScale.gray2};
  height: 5px;
  border-radius: 10px;
  margin-top: 7px;
  margin-bottom: 3px;
  width: 100%;
`;

const StyledProgressBar = styled.div`
  background: ${({ $isDisabled, theme }) =>
    $isDisabled ? theme.grayScale.gray3 : theme.tertiaryPalette.green};
  height: 5px;
  border-radius: 10px;
  width: ${props =>
    getProgressBarWidth(props.progress.current, props.progress.total)}%;
`;

const StyledProgressWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const StyledRibbon = styled(Ribbon)`
  height: 25px;
  width: 25px;
  transform: ${({ $isReports }) => !$isReports && 'translateY(-0.5em)'};
`;

const StyledTagWrapper = styled.div`
  transform: translateY(-8px);
`;

const StyledProgressBarTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledProgressBarText = styled(Typography)`
  color: ${({ $isDisabled, theme }) => $isDisabled && theme.grayScale.gray3};
`;

ProgressBar.propTypes = {
  completionDate: PropTypes.string,
  current: PropTypes.number,
  isComplete: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isMyPlan: PropTypes.bool,
  isReports: PropTypes.bool,
  selectedDueDate: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.instanceOf(Date),
  ]),
  showDate: PropTypes.bool,
  startDate: PropTypes.string,
  text: PropTypes.string.isRequired,
  total: PropTypes.number,
};

ProgressBar.defaultProps = {
  completionDate: null,
  current: 0,
  isComplete: false,
  isDisabled: false,
  isMyPlan: false,
  isReports: false,
  selectedDueDate: 0,
  showDate: false,
  startDate: '',
  total: 0,
};

export default ProgressBar;
