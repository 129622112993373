import Constants from 'constants/index';
import { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Container, Draggable } from 'react-smooth-dnd';
import { Card, Divider } from 'cfa-react-components';
import { AddTask } from './AddTask';
import Title from './Title';
import SectionButtons from './SectionButtons';
import TaskContainer from './TaskContainer';

const Section = ({
  isPreview,
  plan,
  refetch,
  section,
  isAllowedToEdit,
  shouldHideDropdownMenu,
  editSectionTitle,
  editMode,
  renameSectionPopUp,
  deleteSectionPopUp,
  onClickThreeDotMenuDeleteTask,
  addProcedure,
  addQuiz,
  onCancel,
  onEdit,
  onDrop,
  setActiveSectionId,
  setIndexToAdd,
  taskUpdated,
  index,
}) => {
  const [showAddTask, setShowAddTask] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <SectionCard data-testid={`SectionCard-${index}`} elevation={1}>
      <Title
        deleteSectionPopUp={deleteSectionPopUp}
        editMode={editMode}
        editSectionTitle={editSectionTitle}
        handleMenuClick={() => setIsOpen(prev => !prev)}
        index={index}
        isAllowedToEdit={isAllowedToEdit}
        isOpen={isOpen}
        isPreview={isPreview}
        onCancel={onCancel}
        onEdit={updatedSectionTitle =>
          onEdit(
            Constants.TRAINING_MENU_OPTIONS.SECTION,
            updatedSectionTitle,
            null,
          )
        }
        renameSectionPopUp={renameSectionPopUp}
        section={section}
        shouldHideDropdownMenu={shouldHideDropdownMenu}
      />
      <SectionDivider variant="fullLength" />
      <ContainerWrapper>
        <Container
          dragHandleSelector=".step-in-section-drag-handle"
          dropPlaceholder={{
            className: 'shadow-on-drop',
          }}
          getChildPayload={idx => {
            return {
              ...plan.sections.find(sect => sect.id === section.id).steps[idx],
              sectionId: section.id,
            };
          }}
          groupName="steps"
          nonDragAreaSelector=".cfa-textfield"
          onDragEnter={() => setActiveSectionId(section.id)}
          onDrop={e => onDrop(e, section.id)}
          onDropReady={addedIndex => setIndexToAdd(addedIndex)}
          style={{ display: 'flex', flexDirection: 'column', gap: '16px' }} // this component is not comptaible with styled components
        >
          {section.steps.map(step => (
            <Draggable key={step.id}>
              <TaskContainer
                className={
                  isPreview ? 'no-drag' : 'step-in-section-drag-handle'
                }
                isAllowedToEdit={isAllowedToEdit}
                isPreview={isPreview}
                onCancel={onCancel}
                onDelete={onClickThreeDotMenuDeleteTask}
                onEdit={onEdit}
                plan={plan}
                sectionId={section.id}
                step={step}
                taskUpdated={taskUpdated}
              />
            </Draggable>
          ))}
        </Container>
      </ContainerWrapper>

      {showAddTask && (
        <AddTask
          isInSection
          plan={plan}
          refetch={refetch}
          sectionId={section.id}
          setShowAddTask={setShowAddTask}
        />
      )}
      {!isPreview && (
        <SectionButtons
          addProcedure={addProcedure}
          addQuiz={addQuiz}
          setShowAddTask={setShowAddTask}
          showAddTask={showAddTask}
        />
      )}
    </SectionCard>
  );
};

export default Section;

const SectionCard = styled(Card)`
  padding: 16px;
  position: relative;
  margin: 24px 0;
`;
const SectionDivider = styled(Divider)`
  margin: 16px 0;
`;

const ContainerWrapper = styled.div`
  width: 100%;
  .shadow-on-drop {
    background-color: ${({ theme }) => theme.grayScale.gray2};
    padding: 10px;
    border-radius: 5px;
  }
`;

Section.defaultProps = {
  refetch: () => {},
  taskUpdated: false,
  isAllowedToEdit: () => {},
  shouldHideDropdownMenu: false,
  editSectionTitle: () => {},
  editMode: false,
  renameSectionPopUp: () => {},
  deleteSectionPopUp: () => {},
  onClickThreeDotMenuDeleteTask: () => {},
  addProcedure: () => {},
  onCancel: () => {},
  onEdit: () => {},
  onDrop: () => {},
  setActiveSectionId: () => {},
  setIndexToAdd: () => {},
  isPreview: false,
  addQuiz: () => {},
};

Section.propTypes = {
  plan: PropTypes.object.isRequired,
  refetch: PropTypes.func,
  section: PropTypes.object.isRequired,
  taskUpdated: PropTypes.bool,
  isAllowedToEdit: PropTypes.func,
  shouldHideDropdownMenu: PropTypes.bool,
  editSectionTitle: PropTypes.func,
  editMode: PropTypes.bool,
  renameSectionPopUp: PropTypes.func,
  deleteSectionPopUp: PropTypes.func,
  onClickThreeDotMenuDeleteTask: PropTypes.func,
  addProcedure: PropTypes.func,
  addQuiz: PropTypes.func,
  onCancel: PropTypes.func,
  onEdit: PropTypes.func,
  onDrop: PropTypes.func,
  setActiveSectionId: PropTypes.func,
  setIndexToAdd: PropTypes.func,
  isPreview: PropTypes.bool,
  index: PropTypes.number.isRequired,
};
