import { createSelector } from 'reselect';

export const selectSearchReducerState = state => state.search;

export const selectQueryId = createSelector(
  selectSearchReducerState,
  reducerState => reducerState.queryId,
);

export const selectStoredQuery = createSelector(
  selectSearchReducerState,
  reducerState => reducerState.query,
);

export const selectSearchResults = createSelector(
  selectSearchReducerState,
  reducerState => reducerState.searchResults,
);

export const selectSearchResult = createSelector(
  selectSearchReducerState,
  reducerState => reducerState.searchResult,
);

export const selectShowingResults = createSelector(
  selectSearchReducerState,
  reducerState => reducerState.showingResults,
);

export const selectResult = createSelector(
  selectSearchReducerState,
  reducerState => reducerState.result,
);

export const selectHasNextPage = createSelector(
  selectSearchReducerState,
  reducerState => reducerState.hasNextPage,
);

export const selectNumFound = createSelector(
  selectSearchReducerState,
  reducerState => reducerState.numFound,
);

export const selectCurrentPage = createSelector(
  selectSearchReducerState,
  reducerState => reducerState.currentPage,
);

export const selectPageSize = createSelector(
  selectSearchReducerState,
  reducerState => reducerState.pageSize,
);

export const selectSearchTerms = createSelector(
  selectSearchReducerState,
  reducerState => reducerState.terms,
);

export const hasNextPage = createSelector(
  selectSearchReducerState,
  reducerState => reducerState.pageNumber * 12 < reducerState.numFound,
);
