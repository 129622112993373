import { createSlice } from '@reduxjs/toolkit';

export interface UserState {
  audience: string;
  email: string;
  features: Features | {};
  firstName: string;
  fullName: string;
  language: string;
  country: Country;
  locations: string[];
  permissions: Permissions;
  userId: string;
  userType: string;
  isGreatBritainUser: boolean;
  isLicensee: boolean;
  franchiseeLocations: any[];
  licenseeLocations: any[];
  supportCenterLocation: string;
  cdn: CDN[] | [];
  locationsWithPermissions: LocationsWithPermission[];
  selectedLocation: LocationsWithPermission[] | null;
}

export interface CDN {
  domain: string;
  path: string;
  expiresIn: number;
  cookies: Cookies;
  queryString: string;
}

export interface Cookies {
  'CloudFront-Policy': string;
  'CloudFront-Signature': string;
  'CloudFront-Key-Pair-Id': string;
}

export interface Country {
  id?: string;
}

export interface Features {
  plans: Plans;
  quizzes: Plans;
  reporting: Reporting;
  settings: Settings;
  admin: Admin;
  analytics: Admin;
  monitoring: Admin;
}

export interface Admin {
  enabled: boolean;
}

export interface Plans {
  enabled: boolean;
  create: Admin;
}

export interface Reporting {
  enabled: boolean;
  plans: Admin;
  teamMember: Admin;
  compliance: Admin;
  licensee: Admin;
}

export interface Settings {
  enabled: boolean;
  country: Admin;
  language: Admin;
  assistance: Admin;
  location: Admin;
  compliance: Admin;
}

export interface LocationsWithPermission {
  location: Location;
  permissions: string[];
}

export interface Location {
  number: string;
  name: string;
  businessModel: string;
  entity: string;
  type: string;
  country: string;
  state: string;
  operator: Country;
}

export interface Permissions {
  [key: string]: string[];
}

const initialState: UserState = {
  audience: '',
  cdn: [],
  email: '',
  features: {},
  firstName: '',
  fullName: '',
  isGreatBritainUser: false,
  isLicensee: false,
  language: '',
  country: {},
  locations: [],
  locationsWithPermissions: [],
  permissions: {},
  selectedLocation: null,
  userId: '',
  userType: '',
  // below locations are only applicable to operators, these are locations you operate
  franchiseeLocations: [],
  licenseeLocations: [],
  supportCenterLocation: '',
};

export const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    initializeUser: (state, action) => ({
      ...state,
      ...action.payload,
    }),
    initializeUserFromAuth: (state, action) => ({
      ...state,
      ...action.payload,
    }),
  },
});

// Action creators are generated for each case reducer function
export const { initializeUser, initializeUserFromAuth } = slice.actions;

export default slice.reducer;
