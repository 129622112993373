import history from 'util/history';
import Constants from 'constants/index';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';

export const restoreOriginalUri = async (_oktaAuth, originalUri) => {
  if (originalUri) {
    history.replace(toRelativeUrl(originalUri, window.location.origin));
  } else {
    history.push('/');
  }
};

/* eslint-disable */
const transformAuthState = async (oktaAuth, authState) => {
  if (Constants.IS_IN_CYPRESS_TEST) {
    authState.isAuthenticated = true;
    authState.idToken = 'dev';
    authState.accessToken = 'dev';
    return authState;
  }
  // below from: https://github.com/okta/okta-auth-js
  // extra requirement: user must have valid Okta SSO session
  // const user = await oktaAuth.token.getUserInfo();
  // authState.isAuthenticated = true; // convert to boolean
  // authState.users = user; // also store user object on authState
  return authState;
};

const oktaConfig = {
  issuer: Constants.OKTA_ISSUER || 'http://bogus.jest',
  clientId: Constants.OKTA_CLIENT_ID || 'jest',
  redirectUri: window.location.origin + '/login/callback',
  pkce: true,
  scopes: ['openid', 'email', 'profile', 'location_info', 'job_info'],
  transformAuthState,
};
const oktaAuth = new OktaAuth(oktaConfig);

// oktaAuth.authStateManager.subscribe(e => debugger)

export default oktaAuth;
