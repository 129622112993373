import Constants from 'constants/index';
import { useDeviceInfo } from 'util/device';
import { validateTextField } from 'util/validateTextField';
import { isEqual } from 'lodash/lang';
import theme from 'styles/theme';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { selectUserLanguage } from 'store/user/selectors';
import { useState, useEffect } from 'react';
import {
  Card,
  CardContent,
  Divider,
  Dropdown,
  TextField,
} from 'cfa-react-components';
import { IconAlertOctagonFilled, IconTrash } from '@tabler/icons-react';
import ConfirmationModal from 'sharedComponents/app/popups/ConfirmationModal';
import BuildQuizzesAnswers from './BuildQuizzesAnswers';

const BuildQuizzesQuestionCard = ({
  isEditMode,
  quiz,
  setQuiz,
  questionIndex,
  validationMode,
  setValidationMode,
  handlePassingGrade,
}) => {
  const { t } = useTranslation();
  const { isDesktop: isDesktopWidth } = useDeviceInfo();
  const userLanguage = useSelector(selectUserLanguage);
  const questionTemplateTypesArray = Object.values(
    Constants.QUESTION_TEMPLATE_TYPES,
  );

  const [questionTemplateType, setQuestionTemplateType] = useState(
    Constants.QUESTION_TEMPLATE_TYPES.MULTIPLE_CHOICE,
  );
  const [showDeleteQuestionPopup, setShowDeleteQuestionPopup] = useState(false);
  const [correctOptionSelected, setCorrectOptionSelected] = useState(false);

  useEffect(() => {
    if (
      quiz.questions[questionIndex].answers.some(
        option => option.correct === true,
      )
    ) {
      setCorrectOptionSelected(true);
    }
  }, [questionIndex, quiz.questions]);

  useEffect(() => {
    const answers = quiz.questions[questionIndex].answers.map(
      ({ correct, id, referenceId, ...rest }) => ({ ...rest }),
    );
    const defaultTrueFalseAnswers =
      Constants.DEFAULT_TRUE_FALSE_QUESTION.answers.map(
        ({ correct, ...rest }) => ({ ...rest }),
      );

    if (isEqual(answers, defaultTrueFalseAnswers)) {
      setQuestionTemplateType(Constants.QUESTION_TEMPLATE_TYPES.TRUE_OR_FALSE);
    } else {
      setQuestionTemplateType(
        Constants.QUESTION_TEMPLATE_TYPES.MULTIPLE_CHOICE,
      );
    }
  }, [questionIndex, quiz.questions]);

  const handleQuestionName = value => {
    setQuiz(prevState => ({
      ...prevState,
      questions: Object.values({
        ...prevState.questions,
        [questionIndex]: {
          ...prevState.questions[questionIndex],
          answers: [...prevState.questions[questionIndex].answers],
          question: {
            ...prevState.questions[questionIndex].question,
            [userLanguage]: value,
          },
        },
      }),
    }));
  };

  const handleTemplateChange = value => {
    if (value.id !== questionTemplateType.id) {
      setValidationMode(false);
      setCorrectOptionSelected(false);
      const { question, ...newTemplateAnswers } =
        value.id === Constants.QUESTION_TEMPLATE_TYPES.MULTIPLE_CHOICE.id
          ? Constants.DEFAULT_MULTIPLE_CHOICE_QUESTION
          : Constants.DEFAULT_TRUE_FALSE_QUESTION;
      setQuiz(prevState => ({
        ...prevState,
        questions: Object.values({
          ...prevState.questions,
          [questionIndex]: {
            ...prevState.questions[questionIndex],
            ...newTemplateAnswers,
          },
        }),
      }));
      setQuestionTemplateType(value);
    }
  };

  const deleteQuestion = () => {
    handlePassingGrade('');
    if (!isEditMode) {
      window.localStorage.setItem(
        'stored-quiz',
        JSON.stringify({
          ...quiz,
          questions: quiz.questions.filter(
            (question, index) => index !== questionIndex,
          ),
        }),
      );
    }
    setQuiz(prevState => ({
      ...prevState,
      questions: prevState.questions.filter(
        (question, index) => index !== questionIndex,
      ),
    }));
    setShowDeleteQuestionPopup(false);
  };

  return (
    <StyledCard elevation={1} variant="outlined">
      <CardContent>
        <StyledQuestionTitleRow $isDesktop={isDesktopWidth}>
          <StyledTextFieldWrapper $isDesktop={isDesktopWidth}>
            <TextField
              data-testid="EnterQuestion"
              errorText={
                !validateTextField(
                  quiz.questions[questionIndex].question?.[userLanguage] ?? '',
                ) && validationMode
                  ? t('TrainingPlans.buildQuizzes.errorQuestion')
                  : ''
              }
              fullWidth
              label={`${t('Generic.question')} ${questionIndex + 1}`}
              maxLength={250}
              onChange={e => handleQuestionName(e.target.value)}
              placeholder={t('TrainingPlans.buildQuizzes.enterQuestion')}
              required
              value={
                quiz.questions[questionIndex].question?.[userLanguage] ?? ''
              }
            />
          </StyledTextFieldWrapper>
          <StyledDropdownWrapper $isDesktop={isDesktopWidth}>
            <Dropdown
              data-testid={t(questionTemplateType.templateName).replace(
                /\s/g,
                '',
              )}
              fullWidth
              getOptionId={option => option.id}
              getOptionText={option => t(option.templateName)}
              onChange={newVal => handleTemplateChange(newVal)}
              options={questionTemplateTypesArray}
              renderOption={option => t(option.templateName)}
              value={questionTemplateType}
            />
          </StyledDropdownWrapper>
        </StyledQuestionTitleRow>
        <StyledInstructions>
          {questionTemplateType.isMultipleChoiceTemplate
            ? t('TrainingPlans.buildQuizzes.buildQuestionMultipleChoice')
            : t('TrainingPlans.buildQuizzes.buildQuestionTrueFalse')}
          {!correctOptionSelected && validationMode && (
            <StyledSelectError>
              <StyledErrorIcon />
              {t('TrainingPlans.buildQuizzes.errorSelectCorrectAnswer')}
            </StyledSelectError>
          )}
        </StyledInstructions>
        <BuildQuizzesAnswers
          questionIndex={questionIndex}
          questionTemplateType={questionTemplateType}
          quiz={quiz}
          setQuiz={setQuiz}
          setValidationMode={setValidationMode}
          validationMode={validationMode}
        />
        {quiz.questions.length > 1 && (
          <>
            <Divider orientation="horizontal" variant="middle" />
            <StyledDeleteWrapper>
              <IconTrash
                onClick={() => setShowDeleteQuestionPopup(true)}
                size={24}
                style={{
                  color: `${theme.grayScale.gray6}`,
                  cursor: 'pointer',
                }}
              />
            </StyledDeleteWrapper>
          </>
        )}
      </CardContent>

      <ConfirmationModal
        bodyText={t('Generic.deleteUnnamedObject', {
          object: t('Generic.question').toLowerCase(),
        })}
        headerText={t('Generic.deleteHeader', {
          type: t('Generic.question'),
        })}
        isOpen={showDeleteQuestionPopup}
        onClose={() => setShowDeleteQuestionPopup(false)}
        primaryButtonColor={'primary'}
        primaryButtonHandler={deleteQuestion}
        primaryButtonText={t('Button.delete')}
        primaryButtonVariant="destructive"
        secondaryButtonHandler={() => setShowDeleteQuestionPopup(false)}
        secondaryButtonText={t('Button.cancel')}
      />
    </StyledCard>
  );
};

const StyledCard = styled(Card)`
  margin-bottom: 1em;
`;

const StyledQuestionTitleRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: baseline;
  flex-direction: ${props => (props.$isDesktop ? 'row' : 'column')};
`;

const StyledTextFieldWrapper = styled.div`
  flex: 4;
  width: ${props => (props.$isDesktop ? null : '100%')};
`;

const StyledDropdownWrapper = styled.div`
  margin-left: 7px;
  height: 48px;
  flex: 2;
  width: ${props => (props.$isDesktop ? null : '100%')};
`;

const StyledInstructions = styled.div`
  margin-top: 1em;
`;

const StyledSelectError = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 8px;
  font-weight: 600;
  font-size: 12px;
  color: ${theme.semanticColors.error};
`;

const StyledErrorIcon = styled(IconAlertOctagonFilled)`
  margin-right: 8px;
  height: 16px;
  width: 16px;
`;

const StyledDeleteWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 2em 1em 1em 0;
`;

BuildQuizzesQuestionCard.propTypes = {
  isEditMode: PropTypes.bool.isRequired,
  quiz: PropTypes.object.isRequired,
  setQuiz: PropTypes.func.isRequired,
  validationMode: PropTypes.bool.isRequired,
  setValidationMode: PropTypes.func.isRequired,
  questionIndex: PropTypes.number.isRequired,
  handlePassingGrade: PropTypes.func.isRequired,
};

export default BuildQuizzesQuestionCard;
