import { IconCornerRightDown } from '@tabler/icons-react';
import { Typography } from 'cfa-react-components';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const TableOfContents = ({ isMobile, tocItems }) => {
  const { t } = useTranslation();

  return (
    <ListWrapper $isMobile={isMobile}>
      <TableOfContentsHeader>{t('Generic.onThisPage')}</TableOfContentsHeader>
      {tocItems.map((item, index) => (
        <li key={index} style={{ marginBottom: '8px' }}>
          <StyledLink href={`#${item.id}`}>
            <CornerRightIcon />
            {item.text}
          </StyledLink>
        </li>
      ))}
    </ListWrapper>
  );
};

TableOfContents.propTypes = {
  isMobile: PropTypes.bool,
  tocItems: PropTypes.array,
};

TableOfContents.defaultProps = {
  isMobile: false,
  tocItems: [],
};

const ListWrapper = styled.ul`
  list-style-type: none;
  margin-top: ${({ $isMobile }) => ($isMobile ? '-10%' : '10%')};
  padding: ${({ $isMobile }) => $isMobile && 0};
  max-width: 272px;
`;

const TableOfContentsHeader = styled(Typography)`
  color: ${({ theme }) => theme.primaryPalette.navyBlue};
  font-weight: 700;
  margin-bottom: 8px;
`;

const StyledLink = styled.a`
  color: ${({ theme }) => theme.primaryPalette.navyBlue} !important;
  text-decoration: none !important;
`;

const CornerRightIcon = styled(IconCornerRightDown)`
  height: 16px;
  width: 16px;
  margin-right: 4px;
`;

export default TableOfContents;
