import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import PopoverMenuButton from 'components/PopoverMenuButton/PopoverMenuButton';
import PopoverMenuItemButton from 'components/PopoverMenuButton/PopoverMenuButtonItem';
import {
  IconCertificate,
  IconCircleCheck,
  IconNotes,
  IconPlus,
} from '@tabler/icons-react';

export default function SectionButtons({
  setShowAddTask,
  addProcedure,
  showAddTask,
  addQuiz,
}) {
  const { t } = useTranslation();

  const hideAddTaskIfAddingTask = () => {
    if (showAddTask) {
      setShowAddTask(false);
    }
  };

  return (
    <StyledSectionButtonsWrapper>
      <PopoverMenuButton
        // data-testid="sectionAddButton"
        size="sm"
        text={
          <AddButton data-testid="sectionAddButton">
            <IconPlus />
            <>{t('Button.addEllipsis')}</>
          </AddButton>
        }
      >
        <PopoverMenuItemButton
          data-testid="sectionAddProcedureButton"
          icon={<IconNotes />}
          onClick={() => {
            hideAddTaskIfAddingTask();
            addProcedure();
          }}
          text={t('Generic.resources')}
        />
        <PopoverMenuItemButton
          data-testid="sectionAddTaskButton"
          icon={<IconCircleCheck />}
          onClick={() => {
            setShowAddTask(true);
          }}
          text={t('Generic.task')}
        />
        <PopoverMenuItemButton
          data-testid="sectionAddQuizButton"
          icon={<IconCertificate />}
          onClick={() => {
            hideAddTaskIfAddingTask();
            addQuiz();
          }}
          text={t('Generic.quiz')}
        />
      </PopoverMenuButton>
    </StyledSectionButtonsWrapper>
  );
}

SectionButtons.propTypes = {
  setShowAddTask: PropTypes.func.isRequired,
  addProcedure: PropTypes.func.isRequired,
  showAddTask: PropTypes.bool.isRequired,
  addQuiz: PropTypes.func.isRequired,
};

const AddButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

const StyledSectionButtonsWrapper = styled.div`
  display: flex;
  margin-top: 16px;
`;
