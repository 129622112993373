import { Typography } from 'cfa-react-components';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { IconLanguage, IconPrinter, IconShare2 } from '@tabler/icons-react';
import { toast } from 'react-hot-toast';
import ToastMessageBlock from 'sharedComponents/app/Toasts/SuccessToast';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import BreadcrumbLinks from '../../components/BreadcrumbLinks/BreadcrumbLinks';
import { setIsPrinting, setIsTranslating } from '../../store/document/slice';

const DocumentTitle = ({ breadcrumbs, icon, isMobile, title }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const onCopyLink = () => {
    navigator.clipboard.writeText(window.location.href);
    toast.custom(toastObj => (
      <ToastMessageBlock id={toastObj.id}>
        {t('Generic.linkCopied')}
      </ToastMessageBlock>
    ));
  };
  return (
    <TitleWrapper $isMobile={isMobile}>
      {breadcrumbs && (
        <BreadcrumbWrapper>
          <BreadcrumbLinks breadcrumbs={breadcrumbs} />
        </BreadcrumbWrapper>
      )}
      <TypographyWrapper $isMobile={isMobile}>
        <Title variant="h2">{title}</Title>
      </TypographyWrapper>
      {isMobile && (
        <>
          <ActionContainer>
            <IconLanguage
              onClick={() =>
                dispatch(setIsTranslating({ isTranslating: true }))
              }
              style={{ marginRight: 12 }}
            />
            <IconPrinter
              onClick={() => dispatch(setIsPrinting({ isPrinting: true }))}
              style={{ marginRight: 12 }}
            />
            <IconShare2 onClick={onCopyLink} style={{ marginRight: 24 }} />
          </ActionContainer>
        </>
      )}
    </TitleWrapper>
  );
};

DocumentTitle.propTypes = {
  breadcrumbs: PropTypes.array,
  icon: PropTypes.string,
  isMobile: PropTypes.bool,
  title: PropTypes.string,
};

DocumentTitle.defaultProps = {
  breadcrumbs: [],
  icon: '',
  isMobile: false,
  title: '',
};

const TitleWrapper = styled.div`
  background: white;
  box-shadow: ${({ theme }) => theme.boxShadow.elevation8};
  width: 100%;
  padding-bottom: ${({ $isMobile }) => ($isMobile ? '1em' : '2.5em')};
`;

const BreadcrumbWrapper = styled.div`
  margin-top: 2em;
  padding-left: 1em;
`;

const TypographyWrapper = styled.div`
  display: ${({ $isMobile }) => $isMobile && 'flex'};
  justify-content: ${({ $isMobile }) => $isMobile && 'center'};
  padding-left: ${({ $isMobile }) => !$isMobile && '1em'};
`;

const Title = styled(Typography)`
  color: ${({ theme }) => theme.primaryPalette.navyBlue};
  margin-top: 8px;
`;

const ActionContainer = styled.div`
  color: ${({ theme }) => theme.primaryPalette.navyBlue};
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
`;

export default DocumentTitle;
