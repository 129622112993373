import { createSlice } from '@reduxjs/toolkit';

export const slice = createSlice({
  name: 'header',
  initialState: {
    isFileNotFound: false,
  },
  reducers: {
    openFileNotFound: state => {
      state.isFileNotFound = true;
    },
    closeFileNotFound: state => {
      state.isFileNotFound = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const { openFileNotFound, closeFileNotFound } = slice.actions;

export default slice.reducer;
