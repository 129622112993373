import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  Typography,
  useBreakpoints,
  useMediaQuery,
} from 'cfa-react-components';
import Searchbar from 'components/Searchbar/Searchbar';

const SearchFilterHeader = ({
  title,
  hideTitle,
  searchPlaceholder,
  searchValue,
  onChange,
  onClear,
  'data-testid': dataTestId,
  className,
}) => {
  const breakpoints = useBreakpoints();
  const isSmAndDown = useMediaQuery(breakpoints.down('sm'));

  return (
    <SearchHeaderContainer
      $hideTitle={hideTitle}
      $isSmAndDown={isSmAndDown}
      className={className}
      data-testid={dataTestId}
    >
      {!hideTitle && <Typography variant="h3">{title}</Typography>}
      <FilterSearchbar
        $hideTitle={hideTitle}
        $isSmAndDown={isSmAndDown}
        fullWidth={isSmAndDown}
        onChange={onChange}
        onClear={onClear}
        placeholder={searchPlaceholder}
        searchValue={searchValue}
      />
    </SearchHeaderContainer>
  );
};

const FilterSearchbar = styled(Searchbar)`
  width: 100%;
  margin-bottom: ${({ $isSmAndDown, $hideTitle }) =>
    $isSmAndDown && !$hideTitle ? '32px' : '0'};
  ${({ $isSmAndDown }) =>
    !$isSmAndDown &&
    `max-width: 300px;
    & div {
      max-width: 300px;
    }
    & input {
      max-width: 300px;
    }
  `}
`;

const SearchHeaderContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: ${({ $isSmAndDown }) =>
    $isSmAndDown ? 'column-reverse' : 'row'};
  justify-content: space-between;
  align-items: ${({ $isSmAndDown }) =>
    $isSmAndDown ? 'flex-start' : 'center'};
  margin: 16px 0;
  margin-bottom: ${({ $hideTitle }) => ($hideTitle ? '0' : '16px')};
`;

SearchFilterHeader.propTypes = {
  title: PropTypes.string,
  hideTitle: PropTypes.bool,
  searchPlaceholder: PropTypes.string.isRequired,
  searchValue: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
  'data-testid': PropTypes.string,
  className: PropTypes.string,
};

SearchFilterHeader.defaultProps = {
  title: '',
  hideTitle: false,
  className: '',
  'data-testid': 'SearchFilterHeader',
};

export default SearchFilterHeader;
