import Constants from 'constants/index';
import {
  getLaunchdDarklyUser,
  getUserInfo,
  getUserInfoFromAuth,
} from 'util/user';
import { getCountryFromApiCountry } from 'util/international';
import { getLdClient } from 'util/ldClient';
import oktaAuth from 'okta/oktaConfig';
import type { OktaAuth, AuthState } from '@okta/okta-auth-js';
import {
  initializeUser as initializeUserFromStore,
  initializeUserFromAuth,
  UserState,
} from 'store/user/slice';
import { setPreferredLicenseeLocation } from 'store/licenseeLocationPicker/slice';
import { selectUser } from 'store/user/selectors';
import { formatBugsnagErrorMessage } from 'bugsnag';
import Bugsnag from '@bugsnag/browser';
import i18n from 'i18next';
import { initializeLanguage } from 'i18n/language';
import type { AppStore } from 'store';
import { LDClient } from 'launchdarkly-react-client-sdk';
import { sendNativeMessage } from './hooks/useNativeListener';

// isNative hook stand in
const reactNativeWebView = window.ReactNativeWebView;

let androidUser;
if (
  reactNativeWebView &&
  typeof reactNativeWebView.injectedObjectJson === 'function'
) {
  androidUser = JSON.parse(
    reactNativeWebView.injectedObjectJson(),
  ).userFromToken;
}
const getUserFromToken = () => {
  if (window.userFromToken) {
    return JSON.parse(window.userFromToken);
  } else if (androidUser) {
    return androidUser;
  }
};
const userFromToken = getUserFromToken();

export const initializeUser = (store: AppStore) => {
  if (Constants.IS_IN_CYPRESS_TEST_LOCAL || Constants.IS_IN_JEST_TEST) {
    const testUser = {
      fullName: 'Will Shakespeare',
      email: 'will@thebard.com',
      userType: 'Staff',
      locations: ['00000'],
      audience: 'STAFF_AUDIENCE',
      language: '',
      country: {},
      permissions: {
        ADMIN: ['00000'],
        LEADER: ['00000'],
        LOGIN: ['00000'],
        OPERATOR: ['00000'],
        TRAINER: ['00000'],
      },
      userId: '12345abcde',
    };
    store.dispatch(initializeUserFromStore(testUser));
    return;
  }

  const isOperatorOrLicensee = claims => {
    return (
      claims['cfa_aud']?.includes(Constants.USER_AUDIENCE.LICENSEE) ||
      claims['cfa_aud']?.includes(Constants.USER_AUDIENCE.OPERATOR)
    );
  };

  const isLicenseeStaff = claims => {
    return claims['cfa_perms']?.['P20']?.LICENSEESTAFF?.length > 0;
  };

  (oktaAuth as OktaAuth).authStateManager.subscribe(
    async (authState: AuthState) => {
      if (!authState?.accessToken?.claims) {
        return;
      }

      if (isLicenseeStaff(authState.accessToken.claims)) {
        getLocationsForLicenseeStaff(authState, store);
      }
      if (isOperatorOrLicensee(authState.accessToken.claims)) {
        getLocationConceptsDataAndStore(authState, store);
      }

      const { language, country } = await getUserLanguageAndCountryPreference(
        authState,
      ).then(
        async ({ langCode, country: userCountry, prefLicenseeLocation }) => {
          if (prefLicenseeLocation) {
            store.dispatch(setPreferredLicenseeLocation(prefLicenseeLocation));
          }

          const remotePreferencesStateToLocalUserObject = {
            language: langCode,
            country: userCountry,
          };
          store.dispatch(
            initializeUserFromStore(remotePreferencesStateToLocalUserObject),
          );
          await i18n.changeLanguage(langCode);
          return remotePreferencesStateToLocalUserObject;
        },
      );
      const user = getUserInfo(
        authState?.accessToken?.claims,
        language,
        country,
      );
      let isValidUser = false;
      if (!user) {
        const err = 'User info cannot be read from token';
        console.error(err);
        Bugsnag.notify(formatBugsnagErrorMessage(err));
      } else if (!user.permissions || !Object.keys(user.permissions)?.length) {
        const err = 'User permissions do not exist in token';
        console.error(err);
        Bugsnag.notify(formatBugsnagErrorMessage(err));
      } else {
        isValidUser = true;
      }
      if (!isValidUser) {
        await oktaAuth.signOut({
          postLogoutRedirectUri: Constants.PATHWAY_LOGOUT_REDIRECT,
        });
      } else {
        getCfaCdnToken(authState?.accessToken?.accessToken, store, user);
        store.dispatch(initializeUserFromStore(user));
      }
    },
  );

  // Loading site from RNWV
  if (userFromToken) {
    if (isOperatorOrLicensee(userFromToken)) {
      getLocationConceptsDataAndStore(userFromToken, store);
    }
    getUserLanguageAndCountryPreference(userFromToken).then(
      async ({ langCode, country: userCountry, prefLicenseeLocation }) => {
        if (prefLicenseeLocation) {
          store.dispatch(setPreferredLicenseeLocation(prefLicenseeLocation));
        }

        const remotePreferencesStateToLocalUserObject = {
          language: langCode,
          country: userCountry,
        };
        store.dispatch(
          initializeUserFromStore(remotePreferencesStateToLocalUserObject),
        );

        await i18n.changeLanguage(langCode).then();
        initializeLanguage();

        const user = getUserInfo(userFromToken, langCode, userCountry);
        let isValidUser = false;
        if (!user) {
          const err = 'User info cannot be read from token';
          console.error(err);
          Bugsnag.notify(formatBugsnagErrorMessage(err));
        } else if (
          !user.permissions ||
          !Object.keys(user.permissions)?.length
        ) {
          const err = 'User permissions do not exist in token';
          console.error(err);
          Bugsnag.notify(formatBugsnagErrorMessage(err));
        } else {
          isValidUser = true;
        }
        if (!isValidUser) {
          oktaAuth.signOut({
            postLogoutRedirectUri: Constants.PATHWAY_LOGOUT_REDIRECT,
          });
        } else {
          store.dispatch(initializeUserFromStore(user));
        }

        getCfaCdnToken(window.accessToken, store, user);
        return remotePreferencesStateToLocalUserObject;
      },
    );
  }
};

const getCfaCdnToken = async (
  accessToken: string,
  store: AppStore,
  user: UserState,
) => {
  try {
    // TODO: remove flag check when fully migrated to XP API
    const ldClient = await getLdClient(user);
    const flags = ldClient.allFlags();
    const usingXpApi = Boolean(flags['xp-api']);
    const baseUrl: string = usingXpApi
      ? Constants.XP_API_BASE_URL
      : Constants.PATHWAY_NATIVE_API_URL;

    const resp = await fetch(`${baseUrl}/auth`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      credentials: 'include',
    });
    const data = await resp.json();
    if (data.cdn && data.features && data.user) {
      const userFromAuth = getUserInfoFromAuth(data);
      store.dispatch(initializeUserFromAuth(userFromAuth));
    }
  } catch (err) {
    Bugsnag.notify(formatBugsnagErrorMessage(err));
  }
};

async function getLocationsForLicenseeStaff(authState, store) {
  const accessToken = window.accessToken || authState?.accessToken?.accessToken;

  return fetch(
    `${Constants.PATHWAY_API.BASE_URL}/locations/?type=${Constants.LOCATION_TYPES.LIC}`,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    },
  )
    .then(data => data.json())
    .then(userLocationData => {
      store.dispatch(
        initializeUserFromStore({
          licenseeLocations:
            userLocationData?.find(
              type => type.conceptType === Constants.LOCATION_TYPES.LIC,
            )?.locations ?? [],
        }),
      );
      return userLocationData;
    })
    .catch(err => {
      Bugsnag.notify(formatBugsnagErrorMessage(err));
    });
}

async function getLocationConceptsDataAndStore(authState, store) {
  const accessToken = window.accessToken || authState?.accessToken?.accessToken;

  return fetch(`${Constants.PATHWAY_API.BASE_URL}/locations/me`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })
    .then(data => data.json())
    .then(userLocationData => {
      store.dispatch(
        initializeUserFromStore({
          franchiseeLocations: userLocationData?.franchiseeLocations ?? [],
          licenseeLocations: userLocationData?.licenseeLocations ?? [],
          supportCenterLocation:
            userLocationData?.supportCenterLocation ?? null,
        }),
      );
      return userLocationData;
    })
    .catch(err => {
      Bugsnag.notify(formatBugsnagErrorMessage(err));
    });
}

function getUserLanguageAndCountryPreference(authState) {
  return new Promise(resolve => {
    let langCode = Constants.LANGUAGE.ENGLISH_LANGUAGE_CODE;
    let country = Constants.SUPPORTED_COUNTRIES.US;
    let prefLicenseeLocation = null;

    // when loaded from RNWV, (ie, userFromToken exists), look for injected window.language setting
    // if language not requested, fallback to fetching the user's preference
    if (userFromToken && window.language) {
      const language =
        window.language || Constants.LANGUAGE.ENGLISH_LANGUAGE_CODE;
      const userCountry = { id: window.country || country?.id };
      return resolve({ langCode: language, country: userCountry });
    }

    const accessToken =
      window.accessToken || authState?.accessToken?.accessToken;

    return fetch(`${Constants.PATHWAY_API.BASE_URL}/users/preferences`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then(data => data.json())
      .then(prefs => {
        const userPrefLang = prefs?.language;
        const userPrefLoc = prefs?.location;
        if (userPrefLang) {
          langCode = userPrefLang;
        }
        if (userPrefLoc) {
          prefLicenseeLocation = userPrefLoc;
        }
        if (prefs?.country) {
          country = getCountryFromApiCountry(prefs.country);
        } else if (
          authState?.accessToken?.claims?.['cfa-loc-iso_country'].includes(
            Constants.SUPPORTED_COUNTRIES.GB.id,
          )
        ) {
          country = Constants.SUPPORTED_COUNTRIES.GB;
        }
      })
      .catch(err => {
        Bugsnag.notify(formatBugsnagErrorMessage(err));
      })
      .finally(() => {
        resolve({ langCode, country, prefLicenseeLocation });
      });
  });
}

export const initializeLaunchDarklyUser = ({
  ldClient,
  store,
}: {
  ldClient: LDClient;
  store: AppStore;
}) => {
  const state = store.getState();
  const user = selectUser(state);
  sendNativeMessage({
    type: 'updateUser',
    payload: {
      user,
    },
  });
  const ldUser = getLaunchdDarklyUser(user);
  // if browser setting > cookies > "Do not track" then user info wont show in Users list page in LD
  // although the user info should still have been posted to LD for use in flag targeting: apparently
  // "Do not track" browser setting impacts the LD analytics api (events.launchdarkly.com)
  // but for user flag targeting the feature flag API (app.launchdarkly.com) is used
  // https://docs.launchdarkly.com/guides/best-practices/user-data
  if (ldUser && ldUser.email) {
    ldClient.identify(ldUser, undefined, function () {});
  }
};
